import CreateCreature from "./pages/assetmaker/CreateCreature";
import CreateItem from "./pages/assetmaker/CreateItem";
import CreateLocation from "./pages/assetmaker/CreateLocation";
import BrowseAssets from "./pages/showassets/BrowseAssets";
import CreateCharacter from "./pages/assetmaker/CreateCharacter";
import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedLayout } from "./components/ProtectedLayout";
import AuthPage from "./pages/authflow/AuthPage";
import FeedbackPage from "./pages/FeedbackPage/FeedbackPage";
import { RequestPasswordReset } from "./pages/authflow/RequestPasswordReset/RequestPasswordReset";
import { ResetPassword } from "./pages/authflow/ResetPassword";
import { UnauthenticatedLayout } from "./components/UnauthenticatedLayout";
import HomePage from "./pages/HomePage/HomePage";
import AssetPage from "./pages/AssetPage/AssetPage";
import { AccountPage } from "./pages/AccountPage/AccountPage";
import { DeleteAccountPage } from "./pages/DeleteAccountPage/DeleteAccountPage";
import { FAQsPage } from "./pages/FAQsPage/FAQsPage";
import SubscriptionsPage from "./pages/SubscriptionsPage/SubscriptionsPage";
import { Campaigns } from "./pages/Campaigns/Campaigns";
import { Campaign } from "./pages/Campaigns/Campaign/Campaign";
import { Scene } from "./pages/Campaigns/Campaign/Episodes/Scene/Scene";

function App() {
  return (
    <div
      id="app-root"
      className="bg-background border-x-red-200"
      style={{ maxWidth: 430 }}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route element={<UnauthenticatedLayout />}>
            <Route path="/login" element={<AuthPage />} />
            <Route path="/forgot-password" element={<RequestPasswordReset />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>

          <Route element={<ProtectedLayout />}>
            <Route path="/" element={<HomePage />} />

            <Route path="/subscriptions" element={<SubscriptionsPage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/faqs" element={<FAQsPage />} />
            <Route path="/feedback" element={<FeedbackPage />} />
            <Route path="/delete-account" element={<DeleteAccountPage />} />
            {/* CHARACTERS */}
            <Route
              path="/:themeId/asset/character/create"
              element={<CreateCharacter />}
            />

            {/* CREATURES */}
            <Route
              path="/:themeId/asset/creature/create"
              element={<CreateCreature />}
            />

            {/* ITEMS */}
            <Route
              path="/:themeId/asset/item/create"
              element={<CreateItem />}
            />

            {/* LOCATIONS */}
            <Route
              path="/:themeId/asset/location/create"
              element={<CreateLocation />}
            />

            {/* ASSET VIEWER */}
            <Route path="/:themeId/asset/:asset/:id" element={<AssetPage />} />

            {/* Browse */}
            <Route path="/browse" element={<BrowseAssets />} />

            {/* Campaigns */}
            <Route path="/campaigns" element={<Campaigns />} />

            {/* Campaign */}
            <Route path="/campaigns/:id" element={<Campaign />} />

            {/* Scene */}
            <Route
              path="/campaigns/:campaignId/seasons/:seasonId/episodes/:episodeId/scenes/:sceneId"
              element={<Scene />}
            />
          </Route>
          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
