import AppShell from "../../components/AppShell";
import { HeaderV3 } from "../../components/headers/HeaderV3/HeaderV3";
import { CampaignCard } from "./components/CampaignCard";
import { useFetch } from "../../context/FetchContext";
import { useQuery } from "@tanstack/react-query";

export const Campaigns = () => {
  const { apiClient } = useFetch();
  const { data: campaigns } = useQuery({
    queryKey: ["campaigns"],
    queryFn: async () => {
      const res = await apiClient.api.v1.campaigns.$get();

      return await res.json();
    },
  });

  return (
    <AppShell
      browseButton={false}
      backBtn={true}
      header={<HeaderV3 title="Campaigns" />}
    >
      <main>
        <p>FREE Demo Campaign</p>
        {campaigns?.map((campaign) => (
          <CampaignCard
            id={campaign.id}
            name={campaign.name}
            key={campaign.id}
            image={campaign.images?.[0]?.url}
          />
        ))}
      </main>
    </AppShell>
  );
};
