import { createContext, useContext, useMemo } from "react";
import axios, { AxiosInstance } from "axios";
import { useAuth } from "./AuthContext";
import { hc } from "hono/client";
import { V1Routes } from "../../../app";

interface FetchContextType {
  authFetch: AxiosInstance;
  apiClient: ReturnType<typeof hc<V1Routes>>;
}
const FetchContext = createContext<FetchContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

const FetchProvider = ({ children }: Props) => {
  const { authState, refresh } = useAuth();

  const apiClient = hc<V1Routes>("/", {
    headers: {
      Authorization: `Bearer ${authState?.accessToken}`,
      credentials: "include",
    },
  });
  const authFetch = useMemo(() => {
    const instance = axios.create({
      baseURL: "/api/",
      withCredentials: true,
    });

    instance.interceptors.request.use(
      (config) => {
        if (authState?.accessToken) {
          config.headers.Authorization = `Bearer ${authState.accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;
          try {
            const data = await refresh();
            prevRequest.headers.Authorization = `Bearer ${data.accessToken}`;
            return instance(prevRequest);
          } catch (refreshError) {
            console.error("Failed to refresh token:", refreshError);
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }, [authState, refresh]);

  return (
    <FetchContext.Provider value={{ authFetch, apiClient }}>
      {children}
    </FetchContext.Provider>
  );
};

const useFetch = (): FetchContextType => {
  const context = useContext(FetchContext);
  if (!context) {
    throw new Error("useFetch must be used within a FetchProvider");
  }
  return context;
};

export { useFetch, FetchProvider };
