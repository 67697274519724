import { Link } from "react-router-dom";

interface IconButtonProps {
  icon: React.ReactElement;
  onClick?: () => void;
  to?: string;
  text?: string;
  type: "button" | "link";
  className?: string;
  style?: React.CSSProperties;
  isActive?: boolean;
}

const IconButton = ({
  icon,
  onClick = () => {},
  text,
  type,
  to = "#",
  className = "",
  style,
  isActive,
}: IconButtonProps) => {
  return (
    <div
      className={`${className} flex-col justify-between relative`}
      style={{ ...style }}
    >
      {isActive && (
        <div
          style={{
            position: "absolute",
            bottom: -32,
            background: "var(--highlight)",
            width: "100%",
          }}
          className="blurred"
        />
      )}

      {type === "button" ? (
        <button
          className="flex-col items-center justify-center bg-trans"
          style={{ cursor: "pointer" }}
          onClick={onClick}
        >
          {icon}
        </button>
      ) : (
        <Link className="flex-col items-center justify-center" to={to}>
          {icon}
        </Link>
      )}

      {text && (
        <p style={{ fontSize: 12 }} className="m-none flex-row justify-center">
          {text}
        </p>
      )}
    </div>
  );
};

export default IconButton;
