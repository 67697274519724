import styles from "./DeleteAccountPage.module.css";
import { IconArrowLeft } from "@tabler/icons-react";
import deleteAccountImage from "../../assets/delete-account-bg.png";
import { Link } from "react-router-dom";
import { Button } from "../../components/buttons/Button";
import { useMutation } from "@tanstack/react-query";
import { useFetch } from "../../context/FetchContext";
import { useState } from "react";

export const DeleteAccountPage = () => {
  const [areYouSure, setAreYouSure] = useState(false);
  const { apiClient } = useFetch();
  const { mutate: deleteAccount, isPending } = useMutation({
    mutationFn: async () => {
      await apiClient.api.v1.users.me.$delete();
    },
    onSuccess: () => {
      window.location.href = `${window.location.origin}/`;
    },
  });

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Link to="/account" className={styles.backLink}>
          <IconArrowLeft className={styles.backIcon} />
        </Link>
        <h3>FAQs</h3>
      </header>
      <main>
        <img
          src={deleteAccountImage}
          alt="delete-account-image"
          className={styles.deleteAccountImage}
        />
        <div className={styles.deleteAccountContent}>
          <h1>{areYouSure ? "Are you sure?" : "Delete Account"}</h1>
          <p className={styles.deleteAccountText}>
            {areYouSure ? (
              <p>Are you sure you want to delete your account?</p>
            ) : (
              <p>
                We're sorry to see you go! To delete your account, please click
                the button below.
              </p>
            )}
          </p>
          <Button
            loading={isPending}
            variant="secondary"
            className={styles.deleteAccountButton}
            onClick={() => {
              if (areYouSure) {
                deleteAccount();
              } else {
                setAreYouSure(true);
              }
            }}
          >
            {areYouSure ? "Yes, delete everything" : "Delete Account"}
          </Button>
        </div>
      </main>
    </div>
  );
};
