import { QueryFunction, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { useAuth } from "../context/AuthContext";

export const useAssetWithWebSocket = (
  namespace: string,
  id: string | undefined,
  fetchFunction: QueryFunction<any, string[], never>
) => {
  const queryClient = useQueryClient();
  const { authState } = useAuth();
  const wsRef = useRef<WebSocket | null>(null);

  const { data, isLoading, isError } = useQuery({
    queryKey: [namespace, id || ""],
    queryFn: fetchFunction,
    enabled: !!id,
  });

  useEffect(() => {
    if (!id || !authState.accessToken) return;

    const wsUrl = new URL(window.location.origin + `/ws/${namespace}/${id}`);
    wsUrl.protocol = wsUrl.protocol.replace("http", "ws");

    const ws = new WebSocket(wsUrl.toString());
    wsRef.current = ws;

    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          type: "auth",
          token: authState.accessToken,
        })
      );
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "update") {
        queryClient.setQueryData([namespace, id], (oldData: any) => ({
          ...oldData,
          ...message.data,
        }));
      }
    };

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [id, queryClient, namespace, authState.accessToken]);

  return { data, isLoading, isError };
};
