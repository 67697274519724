import { IconChevronRight } from "@tabler/icons-react";
import { useState } from "react";
import styles from "./AccordionItem.module.css";
export const AccordionItem = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.accordionItem}>
      <button
        className={styles.accordionButton}
        onClick={() => setIsOpen(!isOpen)}
      >
        <IconChevronRight
          className={`${styles.icon} ${isOpen ? styles.iconRotated : ""}`}
          size={24}
        />
        <span className={styles.question}>{question}</span>
      </button>
      {isOpen && <div className={styles.answer}>{answer}</div>}
    </div>
  );
};
