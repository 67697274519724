import { Themes } from "../../enums/enums";
import { useState } from "react";
import { Link } from "react-router-dom";
import RadioButtons from "../RadioButtons";
import CharacterIcon from "../../icons/CharacterIcon";
import CreatureIcon from "../../icons/CreatureIcon";
import ItemIcon from "../../icons/ItemIcon";
import LocationIcon from "../../icons/LocationIcon";
import Modal from "./Modal";
import styles from "./ChooseAssetModal.module.css";

interface ChooseAssetModalProps {
  active: boolean;
  closeModal: () => void;
}

const ChooseAssetModal = ({ active, closeModal }: ChooseAssetModalProps) => {
  const [theme, setTheme] = useState(Themes.FANTASY);

  const handleSetTheme = (e: Themes) => {
    setTheme(e);
  };

  return (
    <Modal active={active} closeModal={closeModal} title="Create new">
      <div className={styles.assetModalContainer}>
        <RadioButtons
          options={[Themes.FANTASY, Themes.HORROR, Themes.SCIFI]}
          selectedValue={theme}
          onClick={handleSetTheme}
          title="Theme"
        />
        <h3>Asset</h3>
        <div className={styles.assetModalLinkContainer}>
          <Link
            className={styles.assetModalLink}
            to={`/${theme}/asset/character/create`}
          >
            <CharacterIcon iconHeight="46" iconWidth="46" />
            <div>Characters</div>
          </Link>
          <Link
            className={styles.assetModalLink}
            to={`/${theme}/asset/creature/create`}
          >
            <CreatureIcon iconHeight="46" iconWidth="46" />
            <div>Creatures</div>
          </Link>
          <Link
            className={styles.assetModalLink}
            to={`/${theme}/asset/item/create`}
          >
            <ItemIcon iconHeight="46" iconWidth="46" />
            <div>Items</div>
          </Link>
          <Link
            className={styles.assetModalLink}
            to={`/${theme}/asset/location/create`}
          >
            <LocationIcon iconHeight="46" iconWidth="46" />
            <div>Locations</div>
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default ChooseAssetModal;
