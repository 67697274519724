import ReactDOM from "react-dom/client";
import App from "./App";
import "./css/index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./context/AuthContext";
import { FetchProvider } from "./context/FetchContext";
import { BrowserRouter } from "react-router-dom";
import { hc } from "hono/client";
import { V1Routes } from "../../app";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://54fcf397209974af231d3c6eb3d79376@o4507976142487552.ingest.de.sentry.io/4507976148516944",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
// Create a client
const queryClient = new QueryClient();
export const apiClient = hc<V1Routes>("/api/v1", {}).api.v1;

ReactDOM.createRoot(document.getElementById("root")!).render(
  <AuthProvider>
    <FetchProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </BrowserRouter>
    </FetchProvider>
  </AuthProvider>
);
