import { IconArrowLeft } from "@tabler/icons-react";
import styles from "./TermsAndConditionsPage.module.css";
import { NavLink } from "react-router-dom";
import { FlowState } from "../../enums/enums";

export const TermsAndConditionsPage = ({
  handleFlowStateChange,
}: {
  handleFlowStateChange: (state: FlowState) => void;
}) => {
  return (
    <div>
      <header className={styles.header}>
        <NavLink
          to="#"
          onClick={() => handleFlowStateChange(FlowState.SIGNUP)}
          className={styles.backLink}
        >
          <IconArrowLeft className={styles.backIcon} />
        </NavLink>
        <h3>Terms of Service</h3>
      </header>
      <main className={styles.main}>
        <p>
          Please read these Terms of Service carefully before using RPG Playbook
          web operated.
        </p>

        <h3>1. Acceptance of Terms</h3>

        <p>
          By accessing or using the Service, you agree to be bound by these
          Terms. If you disagree with any part of the terms, then you may not
          access the Service.
        </p>

        <h3>2. Description of Service</h3>

        <p>
          RPG Playbook is a web application that allows users to generate
          fictional assets for role-playing games (RPGs) using AI technology.
          The Service provides AI-generated images, backstories, and
          descriptions for these assets.
        </p>

        <h3>3. User Accounts</h3>

        <p>
          3.1. You will be required to create an account to use all features of
          the Service. You are responsible for maintaining the confidentiality
          of your account and password.
        </p>

        <p>
          3.2. You agree to accept responsibility for all activities that occur
          under your account.
        </p>

        <h3>4. User Content and Conduct</h3>

        <p>
          4.1. You retain all rights to any content you submit, post, or display
          on or through the Service.
        </p>

        <p>
          4.2. You are solely responsible for your User Content and the
          consequences of posting or publishing it.
        </p>

        <p>4.3. You agree not to use the Service to:</p>
        <ul className={styles.list}>
          <li>
            a) Create or generate characters that infringe on copyrights,
            trademarks, or other intellectual property rights of third parties.
          </li>
          <li>
            b) Produce content that is illegal, harmful, threatening, abusive,
            harassing, defamatory, or otherwise objectionable.
          </li>
          <li>
            c) Impersonate any person or entity or falsely state or misrepresent
            your affiliation with a person or entity.
          </li>
        </ul>

        <h3>5. Intellectual Property</h3>

        <p>
          5.1. The Service and its original content (excluding User Content),
          features, and functionality are and will remain the exclusive property
          of RPG Playbook and its licensors.
        </p>

        <p>
          5.2. You acknowledge that the AI-generated content provided by the
          Service may be subject to intellectual property rights, and you agree
          not to use such content for commercial purposes without proper
          attribution or permission.
        </p>

        <h3>6. Copyright Policy</h3>

        <p>
          6.1. We respect the intellectual property rights of others and expect
          users to do the same.
        </p>

        <p>
          6.2. We will respond to notices of alleged copyright infringement that
          comply with applicable law and are properly provided to us.
        </p>

        <p>
          6.3. If you believe that your copyrighted work has been copied in a
          way that constitutes copyright infringement, please provide us with
          the necessary information to take appropriate action.
        </p>

        <h3>7. Limitation of Liability</h3>

        <p>
          In no event shall RPG Playbook, nor its directors, employees,
          partners, agents, suppliers, or affiliates, be liable for any
          indirect, incidental, special, consequential or punitive damages,
          including without limitation, loss of profits, data, use, goodwill, or
          other intangible losses, resulting from your access to or use of or
          inability to access or use the Service.
        </p>

        <h3>8. Changes to Terms</h3>

        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. We will provide notice of any significant
          changes to these Terms. Your continued use of the Service after any
          such changes constitutes your acceptance of the new Terms.
        </p>

        <h3>9. Contact Us</h3>

        <p>
          If you have any questions about these Terms, please contact us at
          support@rpg-playbook.com.
        </p>

        <p>
          By using RPG Playbook, you acknowledge that you have read and
          understood these Terms of Service and agree to be bound by them.
        </p>
      </main>
    </div>
  );
};
