import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./AssetPage.module.css";
import { IconX } from "@tabler/icons-react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../context/FetchContext";
import ImageDataOverlay from "../../components/ImageDataOverlay";
import IconButton from "../../components/IconButton";
import ImageLoaderWithTransition from "../../components/ImageLoaderWithTransition/ImageLoaderWithTransition";
import { AssetData } from "../../types";
import { useAssetWithWebSocket } from "../../hooks/useAssetWithSocket";
import Loading from "../../components/Loading";
import { ErrorPage } from "../ErrorPage/ErrorPage";

// Helper function to build threshold list
function buildThresholdList() {
  let thresholds = [];
  let numSteps = 20;

  for (let i = 1.0; i <= numSteps; i++) {
    let ratio = i / numSteps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
}
const AssetPage = () => {
  const { asset = "", id } = useParams<{ asset: string; id: string }>();
  const { authFetch } = useFetch();
  const [headerOpacity, setHeaderOpacity] = useState<number>(0);
  const observerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const fetchAsset = useCallback(async (): Promise<AssetData> => {
    const response = await authFetch.get(`v1/${asset}s/${id}`);
    return response.data;
  }, [authFetch, id, asset]);

  const { data, isError, isLoading } = useAssetWithWebSocket(
    asset,
    id,
    fetchAsset
  );

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "200px",
      threshold: buildThresholdList(),
    };

    const observerCallback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          // When the observer is out of view, set opacity to maximum
          setHeaderOpacity(0.9);
        } else {
          // When in view, calculate opacity based on intersection ratio
          const newOpacity = 1 - entry.intersectionRatio;
          setHeaderOpacity(Math.min(newOpacity, 0.9));
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return (
      <ErrorPage
        handleOnClick={() => navigate("/")}
        title="Oops, something went wrong there!"
        buttonText="Return to Home"
      />
    );
  }

  return (
    <div className={styles.pageContainer}>
      <div
        ref={observerRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: "1px", // Minimal height
        }}
      />
      {/* Header */}
      <div
        className={styles.stickyHeader}
        style={{
          backgroundColor: `rgba(3, 14, 27, ${headerOpacity})`,
          transition: "background-color 0.3s ease-in-out",
        }}
      >
        <IconButton
          style={{
            background: "rgb(20, 20, 20, 0.6)",
            width: 40,
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 20,
          }}
          icon={<IconX color="white" size={24} />}
          type="button"
          onClick={() => navigate("/browse")}
        />
      </div>

      {/* Image Container */}
      <ImageLoaderWithTransition
        imageName="asset-image"
        imageUrl={data?.image?.url}
      >
        <ImageDataOverlay
          title={asset === "creature" ? data?.type : data?.name}
          description={
            asset === "character"
              ? data?.role
              : asset === "location"
                ? data?.description
                : ""
          }
          dataOne={
            asset === "creature"
              ? data?.size
              : asset === "character"
                ? data?.species
                : data?.type
          }
          dataTwo={data?.age}
          dataThree={data?.gender}
        />
      </ImageLoaderWithTransition>

      {/* Description */}
      <div className={styles.description}>
        {data?.backstory
          ?.split("\n\n")
          .map((paragraph: string, index: number) => (
            <p key={index}>{paragraph}</p>
          ))}
      </div>
    </div>
  );
};

export default AssetPage;
