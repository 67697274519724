import { useNavigate } from "react-router-dom";
import styles from "./Info.module.css";
import { useState } from "react";
import { useFetch } from "../../../../context/FetchContext";
import { useQuery } from "@tanstack/react-query";
import tempImage from "../../../../assets/temp-images/age-of-fear-thumbnail.png";
import { useModal } from "../../../../components/modals/Modal";
import { Button } from "../../../../components/buttons/Button";

export const Info = ({ campaignId }: { campaignId: string }) => {
  const { apiClient } = useFetch();
  const [activeTab, setActiveTab] = useState("Scenes");
  const { closeModal } = useModal();

  const navigate = useNavigate();

  const tabs = ["Scenes", "GM notes"];

  const { data: campaign } = useQuery({
    queryKey: ["campaign", campaignId],
    queryFn: async () => {
      const res = await apiClient.api.v1.campaigns[":id"].$get({
        param: {
          id: campaignId!,
        },
      });
      return await res.json();
    },
    enabled: !!campaignId,
  });

  return (
    <div className={styles.episodes}>
      <div className={styles.tabContainer}>
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`${styles.tab} ${
              activeTab === tab ? styles.activeTab : ""
            }`}
          >
            <button key={tab} onClick={() => setActiveTab(tab)}>
              {tab}
            </button>
          </div>
        ))}
      </div>
      <div
        className={styles.tabContent}
        onWheel={(e) => e.stopPropagation()}
        onTouchMove={(e) => e.stopPropagation()}
      >
        {activeTab === "Scenes" && (
          <div>
            {campaign?.seasons.map((season) => (
              <div key={season.id}>
                <div className={styles.episodesContainer}>
                  {season.episodes.map((episode) => (
                    <div key={episode.id} className={styles.episodeContainer}>
                      <h4>{episode.title}</h4>
                      <div className={styles.scenesContainer}>
                        {episode.scenes.map((scene) => (
                          <div key={scene.id} className={styles.sceneCard}>
                            <div className={styles.sceneImage}>
                              <img
                                src={scene?.images?.[0]?.url || tempImage}
                                alt=""
                              />
                            </div>
                            <div className={styles.sceneInfo}>
                              <p>Scene {scene.order}</p>
                              <p>{scene.title}</p>
                              <p>{scene.shortDescription}</p>
                              <Button
                                onClick={() => {
                                  closeModal();
                                  navigate({
                                    pathname: `/campaigns/${campaignId}/seasons/${season.id}/episodes/${episode.id}/scenes/${scene.id}`,
                                  });
                                }}
                                className={`${styles.viewBtn} ${styles.primary}`}
                              >
                                View
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
        {/* TODO: Add assets back in at some point after worked out db model */}
        {/* {activeTab === "Assets" && (
          <div>
            <div className="grow grid grid-cols-2 gap-1">
              {campaign?.characters?.map((character: any) => {
                return (
                  <BrowseCard
                    key={`${character?.id}`}
                    img={character?.image?.url || ""}
                    alt="placeholder"
                    title={character?.name || character?.type}
                    description={
                      character?.size || character?.type || character?.role
                    }
                    link={`/fantasy/asset/${character?.assetType}/${character.id}`}
                  />
                );
              })}
            </div>
          </div>
        )} */}
        {activeTab === "GM notes" && (
          <div>
            <div className={styles.premise}>{campaign?.premise}</div>
            <br />
            <div className={styles.setting}>{campaign?.setting}</div>
            <br />
          </div>
        )}
      </div>
    </div>
  );
};
