import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../../../context/FetchContext";
import styles from "./Scene.module.css";
import { IconDots, IconX } from "@tabler/icons-react";
import tempImage from "../../../../../assets/temp-images/image.png";
import { Button } from "../../../../../components/buttons/Button";
import Modal from "../../../../../components/modals/Modal";
import { useState } from "react";
import { Info } from "../../components/Info";

export const Scene = () => {
  const navigate = useNavigate();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const { campaignId, episodeId, sceneId, seasonId } = useParams<{
    campaignId: string;
    episodeId: string;
    sceneId: string;
    seasonId: string;
  }>();

  const { apiClient } = useFetch();
  const { data, isLoading, error } = useQuery({
    queryKey: ["scene", campaignId, seasonId, episodeId, sceneId],
    queryFn: async () => {
      const res = await apiClient.api.v1.campaigns[":id"].seasons[
        ":seasonId"
      ].episodes[":episodeId"].scenes[":sceneId"].$get({
        param: {
          id: campaignId!,
          seasonId: seasonId!,
          episodeId: episodeId!,
          sceneId: sceneId!,
        },
      });
      return res.json();
    },
    enabled: !!campaignId && !!seasonId && !!episodeId && !!sceneId,
  });

  if (isLoading) return <div>Loading...</div>;
  if (error || (data && "error" in data)) return <div>An error occurred</div>;
  if (!data) return null;

  const { currentScene: scene, nextScene, nextEpisode } = data;

  const handleNext = () => {
    if (nextScene) {
      navigate(
        `/campaigns/${campaignId}/seasons/${seasonId}/episodes/${episodeId}/scenes/${nextScene.id}`
      );
    } else if (nextEpisode && nextEpisode.firstScene) {
      navigate(
        `/campaigns/${campaignId}/seasons/${seasonId}/episodes/${nextEpisode.id}/scenes/${nextEpisode.firstScene.id}`
      );
    } else {
      navigate(`/campaigns/${campaignId}`);
    }
    window.scrollTo(0, 0);
  };

  return (
    <div className={styles.scene}>
      <header className={styles.sceneHeader}>
        <div className={styles.modalBtn} onClick={() => setShowInfoModal(true)}>
          <IconDots />
        </div>
        <Link className={styles.closeBtn} to={`/campaigns/${campaignId}`}>
          <IconX color="black" />
        </Link>
      </header>
      <main className={styles.sceneContent}>
        <>
          <div className={styles.sceneImageContainer}>
            <img
              className={styles.sceneImage}
              src={scene.images[0]?.url ?? tempImage}
              alt={scene.title}
            />

            <div className={styles.imageGradient}></div>
          </div>
          <div className={styles.sceneInfo}>
            <div>
              <p>Scene {scene.order}</p>
              <h1>{scene.title}</h1>
              {scene.subtitle && <h2>{scene.subtitle}</h2>}
            </div>
            {scene.openingNarration && (
              <div className={styles.narration}>
                <p>{scene.openingNarration}</p>
              </div>
            )}
            <p>{JSON.stringify(scene.description)}</p>

            {scene.closingNarrations && (
              <div className={styles.narration}>
                <p>{scene.closingNarrations}</p>
              </div>
            )}
          </div>
        </>
      </main>
      <footer className={styles.sceneFooter}>
        <Button style={{ width: "100%" }} onClick={handleNext}>
          {nextScene
            ? "Next Scene"
            : nextEpisode
              ? "Next Episode"
              : "Back to Campaign"}
        </Button>
      </footer>
      <Modal
        fullHeight
        active={showInfoModal}
        closeModal={() => {
          setShowInfoModal(false);
        }}
        title="Info"
      >
        <Info campaignId={campaignId!} />
      </Modal>
    </div>
  );
};
