interface CreatureIconProps {
  style?: React.CSSProperties;
  iconWidth: string;
  iconHeight: string;
}

const CreatureIcon = ({ style, iconWidth, iconHeight }: CreatureIconProps) => {
  return (
    <div className="flex justify-center items-center" style={{ ...style }}>
      <svg
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.8">
          <path
            d="M33.0449 16.4024C33.5096 17.4719 36.6399 17.4577 36.6399 17.4577C35.9879 18.4207 35.1991 19.2593 34.2445 19.9416C34.1724 19.9949 34.104 20.0553 34.0463 20.1015C34.3489 20.6807 34.6803 21.2243 34.9289 21.8071C36.2365 24.8453 35.8042 27.7164 33.9779 30.4135C32.429 32.7019 30.2784 34.3151 27.8073 35.5304C24.8211 36.998 21.6511 37.6767 18.3119 37.6482C15.6462 37.6234 12.977 37.6411 10.3114 37.6411C10.1997 37.6411 10.0844 37.6447 9.97636 37.6305C9.5477 37.5772 9.27033 37.2893 9.37839 36.8842C9.52969 36.305 9.7134 35.7152 9.99798 35.1928C10.7725 33.775 12.0044 32.7978 13.4093 32.0232C15.2032 31.0318 17.1556 30.513 19.1512 30.1008C20.6497 29.7916 22.0654 29.248 23.3442 28.3987C23.8737 28.0469 24.3456 27.6312 24.6878 27.091C25.3542 26.0428 25.1381 24.9874 24.3996 24.1026C24.2339 23.9036 23.8377 23.8397 23.5351 23.8077C22.5733 23.7046 21.7016 24.0209 20.8767 24.4757C19.7528 25.0976 18.773 25.9006 17.93 26.8601C16.9971 27.9261 15.2428 28.0078 14.2306 27.0164C13.8631 26.6575 13.4093 25.5002 14.018 24.8382M14.018 24.8382C14.2918 24.2554 14.5656 23.6691 14.8574 23.0934C14.9222 22.9655 15.0483 22.8305 15.1816 22.7701C16.2442 22.2797 17.3141 21.8035 18.3839 21.3274C18.4488 21.2954 18.5244 21.2847 18.5929 21.2634M14.018 24.8382C13.8956 25.1011 13.7299 25.3001 13.4093 25.3179C13.0779 25.3356 12.887 25.1615 12.7285 24.8986C12.0945 23.829 11.4461 22.7665 10.8049 21.7041C10.5599 21.2954 10.5923 21.0147 10.9418 20.7126C13.3732 18.6019 15.1744 16.0328 16.5252 13.1439C16.8314 12.4936 16.9683 12.4261 17.6923 12.5718C17.6923 12.5718 24.994 12.3657 27.231 7.35181C27.231 7.35181 28.5854 8.95795 27.9766 11.4311C27.9766 11.4311 30.8908 11.6976 32.2488 8.4889C32.2488 8.4889 33.6717 12.1667 31.8418 13.5063C31.8418 13.5063 34.5543 14.1744 36.2509 11.9677C36.2509 11.9677 35.6818 15.8587 33.0341 16.3988"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.3045 17.9987C19.1842 17.9958 19.0958 17.9658 19.0394 17.9045C18.9853 17.8446 18.9853 17.7847 19.0492 17.7294C19.0885 17.6965 19.1351 17.6665 19.1817 17.6366C19.4764 17.451 19.771 17.267 20.0656 17.0829C20.2644 16.9587 20.4682 16.9751 20.6081 17.1278C20.7235 17.2535 20.8414 17.3792 20.9518 17.5064C21.0574 17.6261 20.9862 17.7458 20.7702 17.7818C20.3012 17.8566 19.8299 17.9254 19.3585 17.9958C19.3389 17.9987 19.3168 18.0002 19.302 18.0002L19.3045 17.9987Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
};

export default CreatureIcon;
