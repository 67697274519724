import { useNavigate } from "react-router-dom";
import { useAppShell } from "../../../components/AppShell";
import IconButton from "../../../components/IconButton";
import RpgPlaybookIcon from "../../../components/icons/RpgPlaybookIcon";
import BurgerIcon from "../../../icons/BurgerIcon";
import styles from "../HomePage.module.css";
import { Button } from "../../../components/buttons/Button";

export const HomePageHeader = () => {
  const { handleOpenModal } = useAppShell();
  const navigate = useNavigate();

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <RpgPlaybookIcon className={styles.rpgPlaybookIcon} />
        <h4>RPG Playbook</h4>
        <IconButton icon={<BurgerIcon />} to="/account" type="link" />
      </div>
      <div className={styles.headerButtons}>
        <Button onClick={() => navigate("/browse")}>Browse</Button>
        <Button variant="secondary" onClick={handleOpenModal}>
          Create
        </Button>
      </div>
    </header>
  );
};
