const getCreature = (path: string) => {
  if (path.includes("fantasy")) return fantasyCreatures;
  if (path.includes("horror")) return modernCreatures;
  if (path.includes("scifi")) return sciFiCreatures;
  return [""];
};

export default getCreature;

const fantasyCreatures = [
  "A golden dragonkin",
  "A blink dog",
  "A faerie dragon",
  "A unicorn",
  "A griffon",
  "A chimera",
  "A manticore",
  "A hydra",
  "A basilisk",
  "A cockatrice",
  "A wyvern",
  "A displacer beast",
  "A beholder",
  "A mind flayer",
  "A lich",
  "A vampire",
  "A werewolf",
  "A banshee",
  "A ghost",
  "A wraith",
  "A specter",
  "A revenant",
  "A zombie",
  "A skeleton",
  "A ghoul",
  "A demon",
  "A devil",
  "A rakshasa",
  "A sphinx",
  "A medusa",
  "A harpy",
  "A satyr",
  "A dryad",
  "A nymph",
  "A treant",
];

const modernCreatures = [
  "A zombie",
  "A spirit of a deceased loved one",
  "A poltergeist",
  "A ghost",
  "A vampire",
  "A werewolf",
  "A wendigo",
  "A skinwalker",
  "A siren",
  "A banshee",
  "A ghoul",
  "A demon",
  "A devil",
  "A succubus",
  "An incubus",
  "A wraith",
];

const sciFiCreatures = [
  "A robot",
  "An android",
  "A cyborg",
  "A hologram",
  "A clone",
  "A genetically engineered human",
  "A genetically engineered animal",
  "A genetically engineered plant",
  "A genetically engineered fungus",
  "A sentient AI",
  "A sentient computer program",
  "A sentient spaceship",
  "A sentient weapon",
  "A telepathic alien",
  "A telekinetic alien",
  "A shapeshifting alien",
  "A hive mind alien",
  "A parasitic alien",
  "A symbiotic alien",
  "A silicon-based alien",
  "A gas-based alien",
  "A liquid-based alien",
  "An energy-based alien",
  "A time-traveling alien",
  "A dimension-hopping alien",
  "A reality-warping alien",
  "An interdimensional alien",
  "An extradimensional alien",
  "An ultradimensional alien",
  "A cosmic horror",
  "A godlike being",
  "A celestial being",
  "An eldritch abomination",
  "A Lovecraftian horror",
  "A Star Spawn of Cthulhu",
  "A Deep One",
  "A Mi-Go",
  "A Shoggoth",
  "A Byakhee",
  "A Yithian",
];
