import privacyImage from "../../assets/privacy.png";
import { AccordionItem } from "../../components/AccordionItem/AccordionItem";
import { NavLink } from "react-router-dom";
import { IconArrowLeft } from "@tabler/icons-react";
import styles from "./PrivacyPolicyPage.module.css";
import { FlowState } from "../../enums/enums";

const privacyAccordionData = [
  {
    question: "Introduction",
    answer:
      "At RPG Playbook, we are committed to protecting your privacy and ensuring you have a positive experience on our website. This policy outlines our data collection and use practices. It also describes your choices regarding use, access, and correction of your personal information.",
  },
  {
    question: "Information We Collect",
    answer:
      "We collect personal information (such as email address and username), usage data (how you use RPG Playbook), and AI-generated content (characters, backstories, and images you create).",
  },
  {
    question: "How We Use Your Information",
    answer:
      "We use your information to provide and maintain our service, improve your experience, communicate with you about your account, send updates (with your consent), and ensure the security of our platform.",
  },
  {
    question: "Data Storage and Security",
    answer:
      "We implement various security measures to protect your personal information, but no method of transmission over the Internet is 100% secure.",
  },
  {
    question: "Sharing Your Information",
    answer:
      "We do not sell your personal information. We may share it with your consent, to comply with legal obligations, or to protect our rights and property.",
  },
  {
    question: "Your Rights and Choices",
    answer:
      "You have the right to access, update, or delete your personal information, object to data processing, and request data portability. Contact us to exercise these rights.",
  },
  {
    question: "AI-Generated Content and Copyright",
    answer:
      "You retain rights to the characters and stories you create using our AI tools. We may use anonymized data to improve our AI models.",
  },
  {
    question: "Changes to This Policy",
    answer:
      "We may update this policy from time to time. We will notify you of any changes by posting the new policy on this page and updating the 'Last updated' date.",
  },
];

const PrivacyPolicyPage = ({
  handleFlowStateChange,
}: {
  handleFlowStateChange: (state: FlowState) => void;
}) => {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <NavLink
          to="#"
          onClick={() => handleFlowStateChange(FlowState.SIGNUP)}
          className={styles.backLink}
        >
          <IconArrowLeft className={styles.backIcon} />
        </NavLink>
        <h3>Privacy Policy</h3>
      </header>
      <main>
        <img
          src={privacyImage}
          alt="Privacy policy illustration"
          className={styles.policyImage}
        />
        <div className={styles.accordion}>
          {privacyAccordionData.map((item, index) => (
            <AccordionItem
              key={index}
              question={item.question}
              answer={item.answer}
            />
          ))}
          <p className={styles.contactInfo}>
            If you have any questions about this Privacy Policy, please contact
            us at{" "}
            <a href="mailto:support@rpgplaybook.com">
              support@rpg-playbook.com
            </a>
          </p>
        </div>
      </main>
    </div>
  );
};

export default PrivacyPolicyPage;
