const getLocation = (path: string) => {
  if (path.includes("fantasy")) {
    return fantasyLocations;
  }
  if (path.includes("horror")) {
    return modernLocations;
  }
  if (path.includes("scifi")) {
    return scifiLocations;
  }
  return [""];
};

export default getLocation;

const fantasyLocations = [
  "Dark and dank swamp",
  "Misty and mysterious forest",
  "A barren ashlands under a scorching sun",
  "A frozen mountain pass",
  "The ruins of a once great city",
  "A bustling port town",
  "A hidden valley filled with lush vegetation",
  "A treacherous desert wasteland with shifting sands",
  "A haunted graveyard filled with restless spirits",
  "A towering mountain range with snow-capped peaks",
  "A dense jungle teeming with dangerous wildlife",
  "A vast ocean with treacherous waters",
  "A sprawling metropolis with towers that reach the sky",
  "A peaceful village nestled in the rolling hills",
  "A windswept plain with endless horizons",
  "A mysterious underground cavern filled with glowing crystals and strage mushrooms",
  "A mist-shrouded moor with ancient standing stones",
  "A hidden temple deep in the heart of the jungle",
  "A forgotten castle perched on a rocky cliff overlooking the sea",
  "A bustling marketplace filled with exotic goods and strange smells",
  "A tranquil lake surrounded by towering trees and chirping birds",
  "A dusty desert oasis with cool, refreshing water and palm trees",
  "A snow-covered tundra with howling winds and biting cold temperatures",
  "A lush rainforest with towering trees and colorful birds and insects",
  "A rocky coastline with crashing waves and towering cliffs that stretch into the sky",
  "A vast savannah with rolling grasslands and herds of grazing animals",
  "A magical realm filled with floating islands and strange creatures",
  "A fiery volcano with rivers of molten lava and billowing clouds of ash",
  "A frozen wasteland with icy winds and snow-covered mountains",
  "A gateway to the underworld with dark, twisting tunnels and eerie sounds",
  "A vast iron mine with deep shafts and towering piles of ore",
  "A hellfire forge with roaring flames and molten metal",
  "A hidden library with ancient tomes and forbidden knowledge",
  "A secret laboratory with strange experiments and bubbling vials",
  "A mysterious observatory with telescopes and star charts",
  "A hidden garden with exotic plants and colorful flowers",
  "A magical academy with spellbooks and wands",
  "A grand cathedral with stained glass windows and towering spires",
  "A beastman camp with crude huts and roaring bonfires",
  "A dwarven stronghold with massive gates and intricate carvings",
  "A elven tree city with winding pathways and shimmering lights",
  "A goblin warren with twisting tunnels and hidden traps",
  "A human settlement with bustling streets and crowded markets",
  "A dragon lair with piles of treasure and sleeping beasts",
  "A demon-infested wasteland with swirling clouds of darkness",
  "A celestial realm with floating islands and radiant light",
  "A shadowy underworld with twisting passages and hidden dangers",
  "A hidden dimension with strange geometry and shifting landscapes",
  "A cosmic void with swirling stars and distant galaxies",
  "A dream world with surreal landscapes and strange creatures",
  "A nightmare realm with twisted horrors and dark nightmares",
  "A time portal with swirling energies and shifting timelines",
  "A black portal in the stone floor that leads to the other side",
  "A thousand foot tall wall of ice that stretches as far as the eye can see",
  "A massive tree with branches that reach into the sky",
  "A crystal clear lake with fish that glow in the dark",
  "A field of flowers that sing when the wind blows",
  "A cave with walls that sparkle like diamonds",
  "A river that flows uphill",
  "A forest with trees that grow upside down",
  "A hidden valley filled with lush vegetation",
  "A onyx black tower that reaches into the sky and disappears into the clouds above",
  "A gold and bronze throne that sits atop a mountain of skulls",
  "A field of wheat that grows as tall as a tower and whispers secrets to those who listen",
  "A white stone watchtower that overlooks a vast, empty plain",
];

const modernLocations = [
  "A bustling city street filled with people and traffic",
  "A quiet suburban neighborhood with tree-lined streets",
  "A rundown industrial district with abandoned factories",
  "A high-tech research facility with state-of-the-art equipment",
  "A luxury resort on a tropical island",
  "A sprawling shopping mall with stores and restaurants",
  "A busy airport with planes taking off and landing",
  "A remote wilderness area with rugged",
  "A secret government facility with hidden labs and underground bunkers",
  "A high-security prison with armed guards and electrified fences",
  "A crowded sports stadium with cheering fans",
  "A fancy restaurant with gourmet food and expensive wine",
  "A seedy bar with shady characters and dim lighting",
  "A high-rise office building with glass windows and modern architecture",
  "A rundown apartment building with peeling paint and broken windows",
  "A luxury hotel with marble floors and chandeliers",
  "A college campus with students walking to class",
  "A busy hospital emergency room with doctors and nurses",
  "A suburban park with playgrounds and picnic tables",
  "A beach resort with white sand and crystal-clear water",
  "A mountain lodge with cozy cabins and roaring fireplaces",
];

const scifiLocations = [
  "A bustling spaceport with ships coming and going",
  "A high-tech space station with artificial gravity",
  "A remote mining colony on a distant planet",
  "A futuristic city with flying cars and neon lights",
  "A derelict spaceship drifting through space",
  "A massive starship with hundreds of crew members",
  "A research facility on a distant moon",
  "A hidden base on a barren asteroid",
  "A space battle with lasers and explosions",
  "A cyberpunk city with hackers and corporate espionage",
  "A post-apocalyptic wasteland with mutants and scavengers",
  "A virtual reality world with endless possibilities",
  "A space elevator that reaches into orbit",
  "A wormhole that leads to another galaxy",
  "A time machine that can travel to the past and future",
  "A teleporter that can transport people instantly",
  "A cloaking device that can make objects invisible",
  "A force field that can protect against attacks",
  "A robot army with advanced AI and deadly weapons",
  "A genetic engineering lab with designer babies and super soldiers",
  "A mind control device that can manipulate thoughts and memories",
  "A nanotechnology factory that can build anything from atoms",
  "A quantum computer that can solve complex problems in seconds",
  "A space elevator that reaches into orbit",
  "A wormhole that leads to another galaxy",
  "A time machine that can travel to the past and future",
  "A teleporter that can transport people instantly",
  "A cloaking device that can make objects invisible",
  "A force field that can protect against attacks",
];
