import { Link } from "react-router-dom";
import styles from "../Campaigns.module.css";

export const CampaignCard = (props: {
  image: string;
  id: number;
  name: string;
}) => {
  return (
    <Link to={`/campaigns/${props.id}`}>
      <div className={styles.campaignImageContainer}>
        <img src={props.image} alt={props.name} />
        <div className={styles.campaignImageOverlay}></div>
      </div>
    </Link>
  );
};
