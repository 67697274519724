export enum Ages {
  Young = "Young",
  MiddleAged = "Mid-Age",
  Old = "Old",
}

export enum Genders {
  Male = "Male",
  Female = "Female",
  Neutral = "Neutral",
}

export enum Sizes {
  Small = "Small",
  Medium = "Medium",
  Large = "Large",
}

export enum Magic {
  Mundane = "Mundane",
  Magic = "Magic",
  Cursed = "Cursed",
}
