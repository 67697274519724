import { Link } from "react-router-dom";
import { FlowState } from "../../enums/enums";
import AuthPageHeader from "./AuthPageHeader";
import { useAuth } from "../../context/AuthContext";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "../../components/buttons/Button";
import GoogleIcon from "../../icons/GoogleIcon";

const loginSchema = z
  .object({
    email: z.string().email(),
    password: z.string(),
  })
  .required();

interface IFormInput {
  email: string;
  password: string;
}
interface LoginComponentProps {
  handleFlowStateChange: (value: FlowState) => void;
}

const LoginComponent = ({ handleFlowStateChange }: LoginComponentProps) => {
  const { login, authState, oauthSignup } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: zodResolver(loginSchema),
  });

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    login(data.email, data.password);
  };

  return (
    <div
      style={{
        padding: 16,
        display: "grid",
        justifyContent: "stretch",
        minHeight: "100svh",
      }}
    >
      <AuthPageHeader
        message="Not a member yet?"
        buttonLabel="Sign up"
        onClick={() => handleFlowStateChange(FlowState.SIGNUP)}
      />

      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-1">
            <h1
              className="color-white"
              style={{
                fontSize: 24,
                textAlign: "left",
              }}
            >
              Log in
            </h1>
          </div>
          <div className="flex-col gap-1">
            <div>
              <input
                style={{ width: "100%", marginBottom: 0 }}
                placeholder="Email"
                {...register("email")}
              />
              <p style={{ color: "#cd2f22", margin: 0 }}>
                {errors.email?.message}
              </p>
            </div>
            <div>
              <input
                type="password"
                style={{ width: "100%", marginBottom: 0 }}
                placeholder="Password"
                {...register("password")}
              />
              <p style={{ color: "#cd2f22", margin: 0 }}>
                {errors.password?.message}
              </p>
            </div>
          </div>
          <div className="flex-col">
            <Link
              to="/forgot-password"
              style={{
                textAlign: "right",
                marginTop: 10,
              }}
            >
              Forgot password
            </Link>
            <Button type="submit" className="mt-2">
              Sign in
            </Button>
          </div>
        </form>
        <Button
          onClick={oauthSignup}
          variant="transparent"
          style={{
            width: "100%",
            background: "transparent ",
            marginTop: 10,
          }}
        >
          <GoogleIcon />
          <div className="ml-1">Sign up with Google</div>
        </Button>
        {authState.status === "error" && (
          <p style={{ color: "#cd2f22" }}>{authState.error}</p>
        )}
      </div>
    </div>
  );
};

export default LoginComponent;
