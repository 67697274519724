import styles from "./Button.module.css";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "transparent";
  loading?: boolean;
}

export const Button = ({
  children,
  className,
  variant = "primary",
  loading = false,
  disabled,
  ...rest
}: ButtonProps) => {
  return (
    <button
      className={`${styles.btn} ${styles[variant]} ${loading ? styles.loading : ""} ${className}`}
      disabled={disabled || loading}
      {...rest}
    >
      {loading ? "Loading..." : children}
    </button>
  );
};
