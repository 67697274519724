const GoogleIcon = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.7706 11.7554C22.7706 10.8099 22.6939 10.1199 22.5278 9.4043H11.7305V13.6721H18.0683C17.9405 14.7327 17.2505 16.3299 15.7172 17.4032L15.6957 17.5461L19.1096 20.1908L19.3461 20.2144C21.5183 18.2083 22.7706 15.2565 22.7706 11.7554Z"
        fill="#4285F4"
      />
      <path
        d="M11.7305 23.0001C14.8355 23.0001 17.4422 21.9778 19.3461 20.2145L15.7172 17.4033C14.7461 18.0805 13.4427 18.5533 11.7305 18.5533C8.68939 18.5533 6.10826 16.5472 5.18817 13.7744L5.0533 13.7859L1.50345 16.5331L1.45703 16.6622C3.34814 20.4189 7.23264 23.0001 11.7305 23.0001Z"
        fill="#34A853"
      />
      <path
        d="M5.18826 13.774C4.94548 13.0584 4.80498 12.2917 4.80498 11.4995C4.80498 10.7072 4.94548 9.94059 5.17548 9.22504L5.16905 9.07264L1.57472 6.28125L1.45712 6.33719C0.677701 7.89611 0.230469 9.64672 0.230469 11.4995C0.230469 13.3523 0.677701 15.1028 1.45712 16.6618L5.18826 13.774Z"
        fill="#FBBC05"
      />
      <path
        d="M11.7305 4.4462C13.8899 4.4462 15.3466 5.37898 16.1772 6.15849L19.4228 2.98955C17.4295 1.13675 14.8355 -0.000488281 11.7305 -0.000488281C7.23264 -0.000488281 3.34814 2.58063 1.45703 6.33731L5.17539 9.22517C6.10827 6.45236 8.68939 4.4462 11.7305 4.4462Z"
        fill="#EB4335"
      />
    </svg>
  );
};

export default GoogleIcon;
