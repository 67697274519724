import LoginComponent from "../../components/authflow/LoginComponent";
import CreateAccountComponent from "../../components/authflow/CreateAccountComponent";
import CheckYourEmailComponent from "../../components/CheckYourEmailComponent/CheckYourEmailComponent";
import { FlowState } from "../../enums/enums";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { TermsAndConditionsPage } from "../TermsAndConditionsPage/TermsAndConditionsPage";
import PrivacyPolicyPage from "../PrivacyPolicyPage/PrivacyPolicyPage";

const AuthPage = () => {
  const { authState } = useAuth();
  const [flowState, setFlowState] = useState<FlowState>(FlowState.LOGIN);
  const [loginDetails, setLoginDetails] = useState<{
    email: string;
    password: string;
  }>({ email: "", password: "" });

  const handleFlowStateChange = (state: FlowState) => {
    setFlowState(state);
  };

  if (authState.status === "success") {
    return <Navigate to={"/"} />;
  }

  if (authState.status === "error") {
    if (flowState === FlowState.TERMS) {
      return (
        <TermsAndConditionsPage handleFlowStateChange={handleFlowStateChange} />
      );
    }
    if (flowState === FlowState.PRIVACY) {
      return (
        <PrivacyPolicyPage handleFlowStateChange={handleFlowStateChange} />
      );
    }
    if (flowState === FlowState.LOGIN) {
      return <LoginComponent handleFlowStateChange={handleFlowStateChange} />;
    }

    if (flowState === FlowState.SIGNUP) {
      return (
        <CreateAccountComponent
          handleFlowStateChange={handleFlowStateChange}
          setLoginDetails={setLoginDetails}
        />
      );
    }

    if (flowState === FlowState.EMAIL) {
      return (
        <CheckYourEmailComponent
          loginDetails={loginDetails}
          handleFlowStateChange={handleFlowStateChange}
        />
      );
    }

    return <div>Flow State Error</div>;
  }
};

export default AuthPage;
