import IconButton from "../IconButton";
import CrossIcon from "../../icons/CrossIcon";
import { useNavigate } from "react-router-dom";

interface CreateHeader {
  title: string;
  subtitle?: string;
  to?: string;
}

const CreateHeader = ({ title, subtitle, to = "/" }: CreateHeader) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex-row items-start justify-between p-1">
        <div>
          <div
            className="text-xs color-fade"
            style={{ textTransform: "capitalize" }}
          >
            {subtitle}
          </div>
          <div className="text-md" style={{ fontWeight: 600 }}>
            {title}
          </div>
        </div>
        <IconButton
          icon={<CrossIcon style={{}} />}
          type="button"
          onClick={() => navigate(to)}
        />
      </div>
      <div></div>
    </>
  );
};

export default CreateHeader;
