export interface RadioButtonsProps<T> {
  options: T[];
  selectedValue: string;
  onClick: (value: T) => void;
  classes?: string;
  title: string;
}

const RadioButtons = <T extends string>({
  options,
  selectedValue = "",
  onClick,
  classes,
  title,
}: RadioButtonsProps<T>) => {
  return (
    <div
      className="buttons-container flex-col items-start justify-between color-white"
      style={{ marginBottom: 32 }}
    >
      {title && (
        <label
          className="color-white"
          style={{ marginBottom: 10, fontSize: 14, fontWeight: 600 }}
        >
          {title}
        </label>
      )}
      <div
        className="flex-row justify-between"
        style={{ width: "100%", display: "flex" }}
      >
        {options.map((option, index) => {
          return (
            <button
              key={option}
              className={`button rounded-sm border-sm-gray ${classes}`}
              style={{
                backgroundColor:
                  selectedValue === option
                    ? "rgba(255, 255, 255, 0.6)"
                    : "transparent",
                color: selectedValue === option ? "black" : "white",
                height: "42px",
                fontSize: "14px",
                margin: index === options.length - 1 ? "0" : "0 8px 0 0",
                flexGrow: 1,
                flexBasis: "0",
                cursor: "pointer",
                transition: "0.1s",
                textTransform: "capitalize",
              }}
              onClick={() => onClick(option)}
            >
              {option}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default RadioButtons;
