import styles from "./ForgetPasswordEmailVerification.module.css";
import LeftArrowIcon from "../../icons/LeftArrowIcon";
import checkEmailVideo from "../../assets/check-email.mp4";
import { useEffect, useRef } from "react";
import { publicFetch } from "../../utils/fetch";
import { useMutation } from "@tanstack/react-query";

interface Props {
  email: string;
  onBack: () => void;
}

const ForgetPasswordEmailVerification = ({ email, onBack }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  const { mutate } = useMutation({
    mutationFn: async (email: string) => {
      await publicFetch.post("/v1/auth/request-reset", { email });
    },
  });
  return (
    <div className={styles.container}>
      <video
        ref={videoRef}
        autoPlay
        className={styles.backgroundVideo}
        muted
        playsInline
      >
        <source src={checkEmailVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <button onClick={onBack} className={styles.backButton}>
        <LeftArrowIcon />
      </button>
      <div className={styles.content}>
        <h2 className={styles.title}>Check your email</h2>
        <p className={styles.subtitle}>We sent a reset link to</p>
        <p className={styles.email}>{email}</p>
        <p className={styles.resendText}>Didn't receive the email?</p>
        <button onClick={() => mutate(email)} className={styles.resendLink}>
          Click to resend
        </button>
      </div>
    </div>
  );
};

export default ForgetPasswordEmailVerification;
