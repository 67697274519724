import { IconArrowLeft } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import faqImage from "../../assets/privacy.png";
import styles from "./FAQsPage.module.css";
import { AccordionItem } from "../../components/AccordionItem/AccordionItem";

const faqs = [
  {
    question: "What is RPG Playbook?",
    answer:
      "RPG Playbook is an AI-powered service that generates characters, items, locations, and creatures for role-playing games, creative writing, and world building. Each generation includes a detailed 4-paragraph backstory or description and an accompanying image.",
  },
  {
    question: "How does the credit system work?",
    answer:
      "Users pay a monthly subscription fee for a set number of credits. These credits are used to generate characters, items, locations, or creatures within the app. Credits are refreshed at the start of each billing cycle.",
  },
  {
    question: "Do unused credits carry over to the next month?",
    answer:
      "No, credits do not carry over from month to month. Any unused credits expire at the end of your billing cycle.",
  },
  {
    question: "Can I purchase additional credits?",
    answer:
      "Yes, subscribers have the option to purchase additional credit top-ups. This feature is only available to users with an active subscription.",
  },
  {
    question: "What can I create with my credits?",
    answer:
      "You can use your credits to generate characters, items, locations, or creatures. Each generation includes a detailed 5-paragraph backstory or description and an image.",
  },
  {
    question: "How detailed are the generated descriptions?",
    answer:
      "Each generated item comes with a 5-paragraph backstory or description, providing rich detail about the character, item, location, or creature you've created.",
  },
  {
    question: "How is my payment information handled?",
    answer:
      "All credit card data is securely held and processed by Stripe, a trusted third-party payment processor. We do not store your full credit card details on our servers.",
  },
  {
    question: "What user data do you store?",
    answer:
      "We store essential user data including your email address and name in our SQL database. This information is used to manage your account and provide our services.",
  },
  {
    question: "Is my data secure?",
    answer:
      "Yes, we take data security seriously. Your personal information is stored securely in our SQL database, and as mentioned, all payment information is handled by Stripe.",
  },
  {
    question: "I'm experiencing issues with the app. Who should I contact?",
    answer:
      "If you're experiencing any issues with the app, please email our support team at info@rpg-playbook.com. Our technical team will be happy to assist you.",
  },
  {
    question:
      "What happens to my credits and app access when I cancel my subscription?",
    answer:
      "When you cancel your subscription, you will still be able to use the app and your remaining credits until the end of your current billing period. At the end of this period, your subscription will end, and any unused credits will be deleted.",
  },
  {
    question: "Can I use the generated content commercially?",
    answer:
      "Please refer to our terms of service for detailed information on commercial usage of generated content. Generally, subscribers have rights to use the content they generate for personal and commercial purposes, but restrictions may apply.",
  },
  {
    question: "How often are new features or content types added?",
    answer:
      "We regularly update our app with new features and content types. The frequency of updates can vary, but we aim to introduce significant improvements or additions on a quarterly basis. Stay tuned to our newsletter or in-app notifications for the latest updates.",
  },
  {
    question: "Can I edit or customize the generated content?",
    answer:
      "While the app generates detailed descriptions and images, we currently don't offer built-in editing tools. However, you're free to use the generated content as a starting point and modify it using your own tools or methods.",
  },
  {
    question: "Are the generated images copyright-free?",
    answer:
      "The images generated by our app are created using AI and are intended for use by our subscribers. While you have rights to use these images as part of your RPG Playbook subscription, please refer to our terms of service for specific details on image usage and rights.",
  },
  {
    question: "Can I share my generated content with others?",
    answer:
      "Yes, you can share the content you generate with others, such as your gaming group or online community. However, please note that sharing your account credentials or using the service to mass-produce content for distribution is not permitted.",
  },
  {
    question: "Is there a mobile app available?",
    answer:
      "Currently, RPG Playbook is available as a web application optimized for both desktop and mobile browsers. We're exploring the possibility of dedicated mobile apps for iOS and Android in the future.",
  },
  {
    question: "Do you offer any collaboration features for gaming groups?",
    answer:
      "At the moment, we don't have built-in collaboration features. However, you can easily share your generated content with your gaming group through other means. We're considering adding collaboration features in future updates.",
  },
  {
    question: "Can I request specific features or content types?",
    answer:
      "We welcome user feedback and suggestions! While we can't guarantee implementation of every request, we do consider user input when planning future updates. You can submit feature requests through our support email  or feedback form in the app.",
  },
  {
    question: "Is there a limit to how much content I can generate in a day?",
    answer:
      "There's no daily limit on content generation. You can use your credits to generate content at any time, as long as you have available credits in your account.",
  },
  {
    question:
      "Do you offer any tutorials or guides on how to use the generated content in games?",
    answer:
      "We're working on creating a series of tutorials and guides to help users make the most of their generated content. These will be available through newsletters.",
  },
];

export const FAQsPage = () => {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Link to="/account" className={styles.backLink}>
          <IconArrowLeft className={styles.backIcon} />
        </Link>
        <h3>FAQs</h3>
      </header>
      <main>
        <img src={faqImage} alt="faq-image" className={styles.faqImage} />
        <div className={styles.accordion}>
          {faqs.map((faq, index) => (
            <AccordionItem
              key={index}
              question={faq.question}
              answer={faq.answer}
            />
          ))}
        </div>
      </main>
    </div>
  );
};
