import { NavLink } from "react-router-dom";
import { FlowState } from "../../enums/enums";
import CheckItem from "../CheckItem";
import AuthPageHeader from "./AuthPageHeader";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "../buttons/Button";
import { useAuth } from "../../context/AuthContext";
import styles from "./CreateAccountComponent.module.css";
import { isAxiosError } from "axios";

const schema = z
  .object({
    email: z.string().email("Invalid email address"),
    password: z
      .string()
      .min(8, "Password must be at least 8 characters")
      .regex(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character"
      )
      .regex(/\d/, "Password must contain at least one number"),
    confirmPassword: z.string(),
    terms: z.boolean(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

type FormData = z.infer<typeof schema>;

interface CreateAccountComponentProps {
  handleFlowStateChange: (value: FlowState) => void;
  setLoginDetails: (value: { email: string; password: string }) => void;
}

const CreateAccountComponent = ({
  handleFlowStateChange,
  setLoginDetails,
}: CreateAccountComponentProps) => {
  const { signup, authState } = useAuth();
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
      terms: false,
    },
  });

  const watchPassword = watch("password", "");
  const watchConfirmPassword = watch("confirmPassword", "");

  const onSubmit = async (data: FormData) => {
    if (data.terms === false) {
      setError("terms", {
        message: "You must agree to the Terms of Service and Privacy Policy.",
      });
      return;
    }
    try {
      await signup(data.email, data.password);
      setLoginDetails({ email: data.email, password: data.password });
      handleFlowStateChange(FlowState.EMAIL);
    } catch (error) {
      if (isAxiosError(error)) {
        setError("root", {
          message: error?.response?.data?.message,
        });
      } else {
        setError("root", {
          message: "Something went wrong, please contact support",
        });
      }
    }
  };

  return (
    <div
      style={{
        padding: 16,
        display: "grid",
        minHeight: "100svh",
      }}
    >
      <AuthPageHeader
        message="Already a member?"
        buttonLabel="Sign In"
        onClick={() => handleFlowStateChange(FlowState.LOGIN)}
      />
      <div>
        <h1 style={{ textAlign: "left", marginBottom: 16 }}>
          Create an account
        </h1>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <input
            style={{ width: "100%" }}
            placeholder="Email"
            type="email"
            {...register("email")}
          />

          <input
            style={{ width: "100%" }}
            placeholder="Password"
            type="password"
            {...register("password")}
          />
          <div
            id="sign-up-screen-input-confirmation"
            className="flex-col items-start color-fade"
          >
            <p style={{ margin: 0 }}>Password must contain:</p>
            <ul className="color-fade mt-1">
              <CheckItem
                name="8 or more characters"
                checked={watchPassword.length >= 8}
              />
              <CheckItem
                name="1 or more special characters"
                checked={/[!@#$%^&*(),.?":{}|<>]/.test(watchPassword)}
              />
              <CheckItem
                name="1 or more numbers"
                checked={/\d/.test(watchPassword)}
              />
              <CheckItem
                name="Passwords match"
                checked={
                  watchPassword === watchConfirmPassword && watchPassword !== ""
                }
              />
            </ul>
          </div>
          <input
            placeholder="Confirm Password"
            type="password"
            {...register("confirmPassword")}
          />
          <div>
            <div className={styles.checkboxWrapper}>
              <input
                type="checkbox"
                id="terms"
                {...register("terms", {
                  required: true,
                })}
              />
              <label htmlFor="terms" className={styles.label}>
                I agree to the{" "}
                <NavLink
                  to="#"
                  onClick={() => handleFlowStateChange(FlowState.TERMS)}
                  className={styles.termsLink}
                >
                  Terms of Service
                </NavLink>{" "}
                and{" "}
                <NavLink
                  to="#"
                  onClick={() => handleFlowStateChange(FlowState.PRIVACY)}
                  className={styles.termsLink}
                >
                  Privacy Policy
                </NavLink>
              </label>
            </div>

            <p className={styles.termsDescription}>
              By creating an account, you agree to our Terms of Service.
            </p>
          </div>
          <Button disabled={!isValid} type="submit">
            Create Account
          </Button>
          {errors.root && (
            <span className={styles.errorMessage}>{errors.root.message}</span>
          )}
          {authState.error ||
            (errors.terms && (
              <span className={styles.errorMessage}>
                {authState.error ||
                  errors.terms?.message ||
                  "Something went wrong"}
              </span>
            ))}
        </form>
      </div>
    </div>
  );
};

export default CreateAccountComponent;
