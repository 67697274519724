interface LocationIconProps {
  style?: React.CSSProperties;
  iconWidth: string;
  iconHeight: string;
}

const LocationIcon = ({ style, iconWidth, iconHeight }: LocationIconProps) => {
  return (
    <div className="flex justify-center items-center" style={{ ...style }}>
      <svg
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.8">
          <g opacity="0.9">
            <path
              d="M11 22.6667H35M27 33.3334V30.6667C27 29.6059 26.5786 28.5885 25.8284 27.8383C25.0783 27.0882 24.0609 26.6667 23 26.6667C21.9391 26.6667 20.9217 27.0882 20.1716 27.8383C19.4214 28.5885 19 29.6059 19 30.6667V33.3334C19 33.687 18.8595 34.0262 18.6095 34.2762C18.3594 34.5263 18.0203 34.6667 17.6667 34.6667H12.3333C11.9797 34.6667 11.6406 34.5263 11.3905 34.2762C11.1405 34.0262 11 33.687 11 33.3334V14.6667H16.3333V18.6667H20.3333V14.6667H25.6667V18.6667H29.6667V14.6667H35V33.3334C35 33.687 34.8595 34.0262 34.6095 34.2762C34.3594 34.5263 34.0203 34.6667 33.6667 34.6667H28.3333C27.9797 34.6667 27.6406 34.5263 27.3905 34.2762C27.1405 34.0262 27 33.687 27 33.3334Z"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default LocationIcon;
