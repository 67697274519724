const getCharacterRaces = (path: string) => {
  if (path.includes("fantasy")) return fantasyRaces;
  if (path.includes("horror")) return modernRaces;
  if (path.includes("scifi")) return sciFiRaces;
  return [""];
};

export default getCharacterRaces;

const fantasyRaces = [
  "Human",
  "Elf (Wild Woods)",
  "Elf (Nobel)",
  "Elf (Dark)",
  "Dwarf (Hill)",
  "Dwarf (Mountain)",
  "Dwarf (Deep Delver)",
  "Orc (Savage)",
  "Orc (Civilized)",
  "Dragonkin",
  "Demi-Demon",
  "Gnome",
  "Half-Elf",
  "Half-Orc",
  "Halfling",
  "Goblin",
  "Fae Touched",
  "Forest Walker",
  "Catfolk",
  "Satyr",
  "Lizardfolk",
];

const modernRaces = [
  "Human",
  "Vampire",
  "Changling",
  "Mage",
  "Construct",
  "Demon",
  "Possessed",
  "Undead",
];

const sciFiRaces = [
  "Human",
  "Zaxx",
  "Martian",
  "Cyborg",
  "Nano-Machine",
  "Digital Construct",
];
