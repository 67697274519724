import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import getLocation from "../../assetData/locationTypes";
import AppShell from "../../components/AppShell";
import Loading from "../../components/Loading";
import TextInput from "../../components/TextInput";
import { useFetch } from "../../context/FetchContext";
import ArrowShuffleIcon from "../../icons/ArrowsShuffleIcon";
import CreateHeader from "../../components/headers/CreateHeader";
import CreditsNeeded from "../../components/CreditsNeeded";
import { ErrorPage } from "../ErrorPage/ErrorPage";
import { Button } from "../../components/buttons/Button";

interface CreateLocationProps {}

const CreateLocation = ({}: CreateLocationProps) => {
  const [credits, setCreadits] = useState(0);

  const location = useLocation();

  useEffect(() => {
    authFetch("/v1/users/me").then((results) => {
      setCreadits(results.data.credits);
    });
  }, []);

  const getRandomLocation = () => {
    const assetLocations = getLocation(location.pathname);

    return assetLocations[Math.floor(Math.random() * assetLocations.length)];
  };

  const { themeId } = useParams();
  const [type, setType] = useState("");
  const [name, setName] = useState("");

  const { authFetch } = useFetch();
  const navigate = useNavigate();

  const handleTypeChange = (value: string) => {
    setType(value);
  };

  const handleNameChange = (value: string) => {
    setName(value);
  };

  const {
    mutate: handleCreateLocation,
    isPending,
    isError,
    reset,
  } = useMutation({
    mutationFn: async () => {
      return await authFetch.post(`v1/locations`, {
        theme: themeId,
        type: type,
        name: name,
      });
    },
    onSuccess: (result) => {
      navigate(`/${themeId}/asset/location/${result.data.id}`);
    },
  });

  if (isError) {
    return <ErrorPage handleOnClick={() => reset()} />;
  }

  if (!isPending) {
    return (
      <AppShell
        header={<CreateHeader title="New Location" subtitle={themeId} />}
      >
        <div className="flex-col grow">
          <div id="character-maker-form" className="grow flex flex-col ">
            <TextInput
              style={{ marginBottom: 32 }}
              label="Type"
              value={type}
              onChange={handleTypeChange}
              onClick={() => setType(getRandomLocation())}
              buttonIcon={<ArrowShuffleIcon />}
              placeholder="Castle, Dungeon, etc."
            />
            <TextInput
              style={{ marginBottom: 32 }}
              label="Name (Optional)"
              value={name}
              onChange={handleNameChange}
              placeholder="Keep of the Old Ones, Lair of the Goblins, etc."
            />
            <div className="grow flex flex-col justify-end">
              <CreditsNeeded credits={credits} creditsNeeded={20} />
              <Button
                variant="secondary"
                onClick={() => {
                  handleCreateLocation();
                }}
              >
                Create a Location
              </Button>
            </div>
          </div>
        </div>
      </AppShell>
    );
  } else {
    return (
      <Loading>
        <p>Creating your asset...</p>
      </Loading>
    );
  }
};

export default CreateLocation;
