import { useState, createContext, useContext } from "react";
import ChooseAssetModel from "./modals/ChooseAssetModal";
import MobileHeader from "./headers/MobileHeader/MobileHeader";

// Create the context
const AppShellContext = createContext<
  | {
      openModal: boolean;
      handleOpenModal: () => void;
    }
  | undefined
>(undefined);

// Create a custom hook for using the context
export const useAppShell = () => {
  const context = useContext(AppShellContext);
  if (context === undefined) {
    throw new Error("useAppShell must be used within an AppShellProvider");
  }
  return context;
};

interface AppShellProps {
  children: React.ReactNode;
  header?: React.ReactElement;
  title?: string;
  backBtn?: boolean;
  browseButton?: boolean;
}

const AppShell = ({
  children,
  header,
  title,
  backBtn,
  browseButton,
}: AppShellProps) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const contextValue = { openModal, handleOpenModal };

  return (
    <AppShellContext.Provider value={contextValue}>
      <div>
        <ChooseAssetModel active={openModal} closeModal={handleOpenModal} />

        {header ?? (
          <MobileHeader
            rightButtonClick={handleOpenModal}
            title={title}
            backBtn={backBtn}
            browseButton={browseButton}
          />
        )}
        <main
          className="flex-col grow px-1 py-1 relative"
          style={{
            background: "none",
            position: "relative",
          }}
        >
          {children}
        </main>
      </div>
    </AppShellContext.Provider>
  );
};

export default AppShell;
