interface CharacterIconProps {
  style?: React.CSSProperties;
  iconWidth: string;
  iconHeight: string;
}

const ItemIcon = ({ style, iconWidth, iconHeight }: CharacterIconProps) => {
  return (
    <div className="flex justify-center items-center" style={{ ...style }}>
      <svg
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.9">
          <path
            opacity="0.8"
            d="M33.6668 17L23.0002 11L12.3335 17M33.6668 17V29L23.0002 35M33.6668 17L23.0002 23M23.0002 35L12.3335 29V17M23.0002 35V23M12.3335 17L23.0002 23"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
};

export default ItemIcon;
