interface TabsProps {
  tabNames: string[];
  activeTab: string;
  onClick: (tabName: any) => void;
  style?: React.CSSProperties;
  fullWidth?: boolean;
}

const Tabs = ({
  tabNames,
  activeTab,
  onClick,
  style,
  fullWidth,
}: TabsProps) => {
  return (
    <div className="mb-1">
      <div className="flex-row justify-start" style={{ ...style }}>
        {tabNames.map((tabName) => {
          const isActive = tabName === activeTab;
          return (
            <div
              role="button"
              key={tabName}
              className={`tab pb-1 mr-3 flex-row justify-start ${isActive ? "border-b-2 border-white" : "color-fade"}`}
              onClick={() => onClick(tabName)}
              style={{
                color: "white",
                opacity: isActive ? 1 : 0.6,
                borderBottom: isActive
                  ? "1px solid white"
                  : "1px solid rgb(100, 100, 100, 0.0)",
                position: "relative",
                top: "1px",
                cursor: "pointer",
                ...(fullWidth && {
                  position: "relative",
                  top: "1px",
                  cursor: "pointer",
                  flex: "1 1 0%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                }),
              }}
            >
              {tabName}
            </div>
          );
        })}
      </div>
      <div
        className="w-full"
        style={{
          borderBottom: "1px solid rgb(100, 100, 100, 0.3)",
        }}
      />
    </div>
  );
};

export default Tabs;
