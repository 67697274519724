import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { publicFetch } from "../utils/fetch";

interface AuthContextType {
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  signup: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  oauthSignup: () => void;
  refresh: () => Promise<{ accessToken: string | null }>;
  authState: AuthState;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthState {
  accessToken: string | null;
  status: "idle" | "pending" | "success" | "error";
  error: string | null;
}

const initialState: AuthState = {
  accessToken: null,
  status: "idle",
  error: null,
};

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authState, setAuthState] = useState<AuthState>(() => {
    return initialState;
  });

  const [_, setUser] = useState({});

  const refresh = useCallback(async () => {
    const response = await publicFetch.get<{
      accessToken: string | null;
      userDetails: any;
    }>("/v1/auth/refresh");
    return response.data;
  }, []);

  useEffect(() => {
    refresh()
      .then((data): void => {
        setAuthState({
          status: "success",
          accessToken: data.accessToken,
          error: null,
        });
        setUser(data.userDetails);
      })
      .catch(() => {
        setAuthState({
          status: "error",
          accessToken: null,
          error: null,
        });
      });
  }, [refresh]);

  const login = useCallback(async (email: string, password: string) => {
    try {
      const response = await publicFetch.post(`/v1/auth/login`, {
        email,
        password,
      });
      setAuthState({
        status: "success",
        accessToken: response.data.accessToken,
        error: null,
      });
    } catch (error) {
      setAuthState({
        status: "error",
        accessToken: null,
        error: "Username or password incorrect",
      });
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      await publicFetch.delete(`/v1/auth/logout`);
      setAuthState({ status: "error", accessToken: null, error: null });
    } catch (error) {
      setAuthState({
        status: "error",
        accessToken: null,
        error: "Logout failed",
      });
    }
  }, []);

  const signup = useCallback(async (email: string, password: string) => {
    await publicFetch.post("v1/auth/register", { email, password });
  }, []);

  const oauthSignup = useCallback(() => {
    try {
      window.location.href = `/api/v1/auth/google`;
    } catch (error) {}
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: authState.status === "success",
        login,
        oauthSignup,
        signup,
        logout,
        authState,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
