import React, { useState, useRef } from "react";
import styles from "./SelectableBadgesCarousel.module.css";

export type AssetType =
  | "Any"
  | "Characters"
  | "Creatures"
  | "Items"
  | "Locations";

const badges: AssetType[] = [
  "Any",
  "Characters",
  "Creatures",
  "Items",
  "Locations",
];

interface BadgeProps {
  label: AssetType;
  isSelected: boolean;
  onClick: (badge: AssetType) => void;
}

interface SelectableBadgesCarouselProps {
  selectedAssetType: AssetType;
  setSelectedAssetType: React.Dispatch<React.SetStateAction<AssetType>>;
}

const Badge = ({ label, isSelected, onClick }: BadgeProps) => (
  <button
    onClick={() => onClick(label)}
    className={`${styles.badge} ${isSelected ? styles.selected : ""}`}
  >
    {label}
  </button>
);

const SelectableBadgesCarousel = ({
  selectedAssetType,
  setSelectedAssetType,
}: SelectableBadgesCarouselProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleBadgeClick = (badge: AssetType): void => {
    setSelectedAssetType(badge);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current!.offsetLeft);
    setScrollLeft(scrollContainerRef.current!.scrollLeft);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current!.offsetLeft;
    const walk = (x - startX) * 2;
    scrollContainerRef.current!.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUpOrLeave = () => {
    setIsDragging(false);
  };

  return (
    <div className={styles.carouselContainer}>
      <div
        className={styles.scrollContainer}
        ref={scrollContainerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUpOrLeave}
        onMouseLeave={handleMouseUpOrLeave}
      >
        {badges.map((badge) => (
          <Badge
            key={badge}
            label={badge}
            isSelected={selectedAssetType.includes(badge)}
            onClick={handleBadgeClick}
          />
        ))}
      </div>
    </div>
  );
};

export default SelectableBadgesCarousel;
