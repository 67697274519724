import errorGoblinImage from "../../assets/error-goblin.png";
import { Button } from "../../components/buttons/Button";
import styles from "./ErrorPage.module.css";

interface Props {
  title?: string;
  subtitle?: string;
  buttonText?: string;
  handleOnClick: () => void;
}

export const ErrorPage = ({
  title = "Oops, something went wrong!",
  subtitle,
  buttonText = "Go back",
  handleOnClick,
}: Props) => {
  return (
    <div className={styles.container}>
      <div>
        <img
          className={styles.image}
          src={errorGoblinImage}
          alt="bramble the goblin tangled in electrical wires"
        />
      </div>
      <div className={styles.content}>
        <h1 className={styles.title}>{title}</h1>
        {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        <div className={styles.buttonContainer}>
          <Button
            onClick={handleOnClick}
            style={{ width: "100%" }}
            variant="secondary"
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};
