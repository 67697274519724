import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { useFetch } from "../context/FetchContext";
import SubscriptionsPage from "../pages/SubscriptionsPage/SubscriptionsPage";

export const ProtectedLayout = () => {
  const { authState } = useAuth();
  const { apiClient } = useFetch();

  const { data: me } = useQuery({
    queryKey: ["me"],
    queryFn: async () => {
      const res = await apiClient.api.v1.users.me.$get();
      return await res.json();
    },
    enabled: authState?.status === "success",
  });

  const location = useLocation();

  if (authState?.status === "pending") {
    return null;
  }

  if (authState?.status === "error") {
    // Redirect them to the login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (authState?.status === "success") {
    if (!me) {
      return null;
    }
    if (me?.onboarded === false) {
      return <SubscriptionsPage />;
    }

    return <Outlet />;
  }
};
