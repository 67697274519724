const getItems = (path: string) => {
  if (path.includes("fantasy")) {
    return fantasyItems;
  }
  if (path.includes("horror")) {
    return horrorItems;
  }
  if (path.includes("scifi")) {
    return scifiItems;
  }
  return [""];
};

export default getItems;

const fantasyItems = [
  "Sword",
  "Shield",
  "Bow",
  "Arrow",
  "Dagger",
  "Staff",
  "Wand",
  "Ring",
  "Amulet",
  "Helmet",
  "Chestplate",
  "Gauntlets",
  "Leggings",
  "Boots",
  "Potion",
  "Scroll",
  "Book",
  "Gem",
  "Coin",
  "Key",
];

const horrorItems = [
  "Knife",
  "Axe",
  "Revolver",
  "Shotgun",
  "Rifle",
  "Crowbar",
  "Machete",
  "Chainsaw",
  "Hammer",
  "Spellbook",
  "Candle",
  "Pentagram",
  "Skull",
  "Coffin",
  "Vial of Blood",
  "Doll",
  "Mirror",
  "Ouija Board",
  "Tarot Cards",
  "Cursed Amulet",
];

const scifiItems = [
  "Blaster",
  "Laser Sword",
  "Plasma Shieid",
  "Energy Dagger",
  "Energy Staff",
  "Tech Armour",
  "Photo Cannon   ",
  "Holo Shield",
  "Cyber Deck",
  "Nanite Injector",
  "Power Cell",
  "Data Cube",
  "Holo Map",
  "Haptic Suit",
  "Neural Implant",
  "Cybernetic Eye",
];
