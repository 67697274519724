import loadingGif from "../assets/fireball.gif";
import "../css/custom/loading.css";

const Loading = ({ children }: { children?: React.ReactNode }) => {
  return (
    <div
      className="laoding flex-col"
      style={{
        margin: "auto",
        maxHeight: "100%",
        alignItems: "center",
      }}
    >
      <img src={loadingGif} alt="" className="fade-in-animation" width={400} />
      {children}
    </div>
  );
};

export default Loading;
