interface CreditsNeededProps {
  credits: number;
  creditsNeeded: number;
}

const CreditsNeeded = ({ credits, creditsNeeded }: CreditsNeededProps) => {
  return (
    <div className="credit-info flex-col justify-between items-end mb-2">
      <div>Credits required: {creditsNeeded}</div>
      <div className="text-sm color-fade" style={{ marginTop: 4 }}>
        My credits: {credits}
      </div>
    </div>
  );
};

export default CreditsNeeded;
