const getCharacterNames = (path: string) => {
  if (path.includes("fantasy")) return fantasyNames;
  if (path.includes("horror")) return modernNames;
  if (path.includes("scifi")) return sciFiNames;
  return [""];
};

export default getCharacterNames;

const fantasyNames = [
  "Arin",
  "Elaris",
  "Fenn",
  "Lyrel",
  "Thaden",
  "Daeris",
  "Quinlan",
  "Cyran",
  "Maris",
  "Elion",
  "Taryn",
  "Soren",
  "Valen",
  "Ilyan",
  "Draven",
  "Kaelin",
  "Talyn",
  "Varyn",
  "Zephyr",
  "Nyx",
  "Ashen",
  "Riven",
  "Alaric",
  "Elowen",
  "Kaelis",
  "Lorian",
  "Aerin",
  "Thalor",
  "Brin",
  "Dorian",
  "Faelan",
  "Kestrel",
  "Phaedrus",
  "Eirian",
  "Arion",
  "Lyria",
  "Theron",
  "Kieran",
  "Maelis",
  "Elys",
  "Daelan",
  "Serin",
  "Galen",
  "Renan",
  "Lycoris",
  "Aelion",
  "Brevin",
  "Nymeria",
  "Calyx",
  "Elara",
  "Kylan",
  "Raelan",
  "Verin",
  "Thalys",
  "Aelith",
  "Lorien",
  "Rhian",
  "Evander",
  "Selene",
  "Kaelith",
  "Faeron",
  "Myrrh",
  "Tanis",
  "Erevan",
  "Vaelis",
  "Arion",
  "Lirien",
  "Theros",
  "Daeris",
  "Sylvan",
  "Zevran",
  "Alistair",
  "Kaelin",
  "Rhylen",
  "Varian",
  "Elion",
  "Caelum",
  "Loriel",
  "Faelith",
  "Aerith",
  "Raelyn",
  "Galad",
  "Nyssa",
  "Seraph",
  "Thalyn",
  "Elaran",
  "Kalais",
  "Therin",
  "Myriel",
  "Daevon",
  "Valeris",
  "Ilyria",
  "Arannis",
  "Loryn",
  "Aeron",
  "Thalia",
  "Elira",
  "Kaelen",
  "Serin",
  "Vaerion",
  "Aelar",
  "Bralin",
  "Daeris",
  "Eryndor",
  "Faryn",
  "Galion",
  "Halyon",
  "Ilaris",
  "Jorlan",
  "Krylan",
  "Lythor",
  "Maelis",
  "Naryn",
  "Orlin",
  "Pyrian",
  "Quorin",
  "Rynan",
  "Sylas",
  "Tyrael",
  "Voryn",
  "Wyler",
  "Xylar",
  "Ylona",
  "Zephan",
  "Aranis",
  "Brannon",
  "Caelen",
  "Daelan",
  "Elara",
  "Faelan",
  "Gryndor",
  "Halcyon",
  "Ilian",
  "Joryn",
  "Kalen",
  "Lorian",
  "Myrrh",
  "Nyrian",
  "Orlith",
  "Prynn",
  "Quirin",
  "Rylian",
  "Syllin",
  "Tylis",
  "Varyn",
  "Wynna",
  "Xyris",
  "Yllara",
  "Zevran",
  "Aelar",
  "Brisyn",
  "Caedyn",
  "Daeris",
  "Elion",
  "Faerith",
  "Glynn",
  "Haleth",
  "Ilyas",
  "Joryth",
  "Kaelen",
  "Lysander",
  "Mylen",
  "Nyla",
  "Oran",
  "Pryor",
  "Quillian",
  "Rylen",
  "Sylar",
  "Taryn",
  "Vyran",
  "Wylan",
  "Xylen",
  "Ylian",
  "Zephyr",
  "Aelar",
  "Brithyn",
  "Caelith",
  "Daeron",
  "Elira",
  "Faelon",
  "Glynnis",
  "Halin",
  "Ilith",
  "Joril",
  "Kaelis",
  "Lyran",
  "Mylar",
  "Nysian",
  "Orith",
  "Pyrrhus",
  "Quen",
  "Rynis",
  "Syren",
  "Tylan",
  "Valin",
  "Wrynn",
  "Xylis",
  "Yllin",
  "Zevra",
  "Aelion",
  "Bronn",
  "Caelyn",
  "Daelith",
  "Elorin",
  "Faelith",
  "Gralin",
  "Halion",
  "Ilian",
  "Jaryn",
  "Kaelin",
  "Lyris",
  "Myron",
  "Nyris",
  "Orlan",
  "Pyra",
  "Quinlan",
  "Rynor",
  "Sylvan",
  "Tyrion",
  "Valis",
  "Wyrin",
  "Xyra",
  "Yloris",
  "Zevra",
  "Aeron",
  "Brithis",
  "Caelon",
  "Daeron",
  "Elin",
  "Faelis",
  "Grynn",
  "Halor",
  "Ilyon",
  "Jorin",
  "Kaelis",
  "Lyrian",
  "Mylan",
  "Nyran",
  "Oren",
  "Pyrr",
  "Quorin",
  "Rylor",
  "Sylis",
  "Tynan",
  "Vyris",
  "Wyren",
  "Xylon",
  "Ylith",
  "Zevran",
  "Aelion",
  "Brien",
  "Caelin",
  "Daerys",
  "Elira",
  "Faelor",
  "Glynna",
  "Halan",
  "Ilian",
  "Jorren",
  "Kaelin",
  "Lyrith",
  "Myrion",
  "Nysor",
  "Orin",
  "Pyrris",
  "Quinlan",
  "Rynlan",
  "Sylor",
  "Tynor",
  "Valis",
  "Wyris",
  "Xylin",
  "Ylian",
  "Zephyr",
  "Aeris",
  "Brithan",
  "Caelin",
  "Daelon",
  "Elion",
  "Faelith",
  "Grylin",
  "Halion",
  "Ilis",
  "Jorlin",
  "Kaelis",
  "Lyris",
  "Myron",
  "Nyris",
  "Orlin",
  "Pyrran",
  "Quinlan",
  "Rylis",
  "Sylvar",
  "Tynar",
  "Valen",
  "Wyran",
  "Xylis",
  "Ylor",
  "Zevra",
  "Aelir",
  "Brithor",
  "Caelir",
  "Daeron",
  "Elira",
  "Faelis",
  "Glynor",
  "Halith",
  "Ilith",
  "Jorrin",
  "Kaelor",
  "Lyrin",
  "Mylar",
  "Nysor",
  "Orlan",
  "Pyrris",
  "Quorin",
  "Rynlan",
  "Sylis",
  "Tynis",
  "Valar",
  "Wyris",
  "Xylon",
  "Ylin",
  "Zevran",
  "Aerin",
  "Brithis",
  "Caelor",
  "Daeron",
  "Elith",
  "Faelis",
  "Grylin",
  "Halin",
  "Ilyon",
  "Jorlin",
  "Kaelor",
  "Lyris",
  "Myrith",
  "Nyrin",
  "Oris",
  "Pyrrith",
  "Quinlan",
  "Rylith",
  "Sylin",
  "Tynor",
  "Valin",
  "Wyris",
  "Xylith",
  "Ylith",
  "Zephyr",
  "Aeron",
  "Brithan",
  "Caelith",
  "Daerith",
  "Elion",
  "Faelith",
  "Glynnis",
  "Halin",
  "Ilyan",
  "Jorrin",
  "Kaelith",
  "Lyran",
  "Myrrh",
  "Nysor",
  "Orlin",
  "Pyrrith",
  "Quinlan",
  "Rylis",
  "Sylor",
  "Tynor",
  "Valis",
  "Wyrin",
  "Xylith",
  "Ylith",
  "Zevran",
  "Aelith",
  "Brithor",
  "Caelith",
  "Daeryn",
  "Elira",
  "Faelis",
  "Grynn",
  "Halion",
  "Ilian",
  "Jorrith",
  "Kaelor",
  "Lyris",
  "Myron",
  "Nyris",
  "Orlin",
  "Pyrrith",
  "Quorin",
  "Rynor",
  "Sylis",
  "Tynar",
  "Valin",
  "Wyris",
  "Xylon",
  "Ylis",
  "Zephra",
  "Aelar",
];

const modernNames = [
  "Charlie",
  "Oliver",
  "Leo",
  "Milo",
  "Jasper",
  "Finn",
  "Oscar",
  "Theo",
  "Arlo",
  "Henry",
  "Hugo",
  "Arthur",
  "Jack",
  "Ezra",
  "Alfie",
  "Freddie",
  "Max",
  "Elijah",
  "Ethan",
  "Isaac",
  "Theodore",
  "Sebastian",
  "Samuel",
  "Alexander",
  "Benjamin",
  "Thomas",
  "William",
  "Louis",
  "James",
  "Daniel",
  "Jacob",
  "Adam",
  "Nathan",
  "Dylan",
  "Matthew",
  "David",
  "Joseph",
  "Ryan",
  "Luke",
  "Michael",
  "Andrew",
  "Joshua",
  "John",
  "George",
  "Christopher",
  "Robert",
  "Yuuma",
  "Kaito",
  "Haruto",
  "Sota",
  "Riku",
  "Yuto",
  "Yuki",
  "Kakeru",
  "Ryusei",
  "Sora",
  "Ren",
  "Haruki",
  "Kazuki",
  "Abena",
  "Abebi",
  "Abena",
  "Abeni",
  "Abidemi",
  "Abimbola",
  "Abioye",
  "Abisola",
  "Abosede",
  "Adanna",
  "Adanne",
  "Zala",
  "Zalika",
  "Zane",
  "Zara",
  "Zareb",
  "Zarek",
  "Zaria",
  "Zayn",
  "Zayna",
  "Zaynab",
  "Zea",
  "Zelda",
  "Zelenia",
  "Bibi",
  "Bijou",
  "Binta",
  "Bintou",
  "Birch",
  "Birchard",
  "Birger",
  "Birk",
  "Birke",
  "Birley",
  "Birney",
  "Biron",
  "Bishop",
  "Bita",
  "Bithron",
  "Bittan",
  "Bitten",
  "Bjarn",
  "Bjarni",
  "Bjarno",
  "Bjarte",
  "Bjartur",
  "Bjorn",
  "Carla",
  "Carlene",
  "Carley",
  "Carli",
  "Carlie",
  "Carlin",
  "Carlina",
  "Carline",
  "Carlisle",
  "Carlita",
  "Carlito",
  "Carlo",
  "Carlon",
  "Carlota",
  "Carlotta",
  "Carlton",
  "Carly",
  "Carlyle",
  "Carmel",
  "Carmela",
  "Carmelita",
  "Darius",
  "Darnell",
  "Darrell",
  "Darren",
  "Darryl",
  "Daryl",
  "Dashawn",
  "Davante",
  "Davari",
  "Dave",
  "Davell",
  "Davida",
  "Davina",
  "Davion",
  "Davis",
  "Davon",
  "Davonte",
  "Dawson",
  "Dax",
  "Daxton",
  "Dayan",
  "Dayana",
  "Dayna",
  "Ella",
  "Ellamae",
  "Ellan",
  "Ellar",
  "Ellard",
  "Ellary",
  "Ellen",
  "Ellena",
  "Ellene",
  "Ellery",
  "Ellesse",
  "Ellette",
  "Ellia",
  "Elliana",
  "Ellie",
  "Ellinor",
  "Elliot",
  "Elliott",
  "Ellis",
  "Ellison",
  "Elliston",
  "Ellora",
  "Ellory",
  "Finn",
  "Finnegan",
  "Finnian",
  "Finnick",
  "Finnigan",
  "Finnley",
  "Finnly",
  "Finnon",
  "Finnucane",
  "Finnula",
  "Finnula",
  "Finnur",
  "Finnvard",
  "Finnvarr",
  "Fergus",
  "Ferrell",
  "Ferris",
  "Finnbar",
  "Finnbarr",
  "Finnegan",
  "Galen",
  "Galenos",
  "Galeron",
  "Gallagher",
  "Gallard",
  "Gallien",
  "Gallio",
  "Gallus",
  "Galt",
  "Galton",
  "Galvin",
  "Galyn",
  "Ganit",
  "Gannon",
  "Ganymede",
  "Gar",
  "Garadin",
  "Garadun",
  "Garadyn",
  "Garai",
  "Garald",
  "Garatun",
  "Harley",
  "Harlow",
  "Harlyn",
  "Harmon",
  "Harold",
  "Harper",
  "Harriet",
  "Harris",
  "Harrison",
  "Harry",
  "Hart",
  "Hartley",
  "Haruko",
  "Harumi",
  "Haruna",
  "Haruno",
  "Haru",
  "Haruka",
  "Haruki",
  "Haruko",
  "Harumi",
  "Haruna",
  "Iris",
  "Irit",
  "Iritana",
  "Iritani",
  "Iritano",
  "Iritara",
  "Iritari",
  "Iritaro",
  "Iritaru",
  "Iritasi",
  "Iritasu",
  "Iritata",
  "Iritate",
  "Iritato",
  "Iritatu",
  "Iritaya",
  "Iritayo",
  "Iritayu",
  "Iritaza",
  "Iritaze",
  "Iritazi",
  "Jasper",
  "Jax",
  "Jaxon",
  "Jay",
  "Jayce",
  "Jayden",
  "Jaylen",
  "Jayson",
  "Jensen",
  "Jeremiah",
  "Jeremy",
  "Jerome",
  "Jesse",
  "Joel",
  "Joey",
  "John",
  "Johnny",
  "Jonah",
  "Jonas",
  "Jonathan",
  "Jordan",
  "Joseph",
  "Josh",
  "Joshua",
  "Kai",
  "Kaiden",
  "Kaleb",
  "Kane",
  "Karl",
  "Karter",
  "Kasen",
  "Kason",
  "Kayden",
  "Kellan",
  "Kellen",
  "Kelvin",
  "Ken",
  "Kendrick",
  "Kenneth",
  "Kenny",
  "Kent",
  "Kenzo",
  "Kevin",
  "Kian",
  "Kieran",
  "Killian",
  "King",
  "Knox",
  "Landon",
  "Lane",
  "Layne",
  "Lawrence",
  "Lawson",
  "Layne",
  "Leland",
  "Lennon",
  "Lennox",
  "Leo",
  "Leon",
  "Leonard",
  "Leonardo",
  "Leonel",
  "Leopold",
  "Leroy",
  "Levi",
  "Lewis",
  "Liam",
  "Lincoln",
  "Lionel",
  "Logan",
  "Lorenzo",
  "Milo",
  "Miles",
  "Miller",
  "Milton",
  "Mitchell",
  "Morgan",
  "Morris",
  "Moses",
  "Myles",
  "Myron",
  "Nash",
  "Nate",
  "Nathan",
  "Nathaniel",
  "Neal",
  "Ned",
  "Neil",
  "Nelson",
  "Nestor",
  "Neville",
  "Nicholas",
  "Nick",
  "Nickolas",
  "Oliver",
  "Ollie",
  "Omar",
  "Orlando",
  "Orson",
  "Oscar",
  "Otis",
  "Otto",
  "Owen",
  "Pablo",
  "Parker",
  "Patrick",
  "Paul",
  "Paxton",
  "Pedro",
  "Percy",
  "Peter",
  "Peyton",
  "Philip",
  "Phillip",
  "Pierce",
  "Preston",
  "Prince",
  "Quentin",
  "Quincy",
  "Quinn",
  "Rafael",
  "Ralph",
  "Ramiro",
  "Ramon",
  "Randal",
  "Randall",
  "Randolph",
  "Randy",
  "Raphael",
  "Ray",
  "Raymond",
  "Reed",
  "Reginald",
  "Reid",
  "Reuben",
  "Rex",
  "Rhett",
  "Richard",
  "Ricky",
  "Riley",
  "River",
  "Robert",
  "Rocco",
  "Rocky",
  "Rodney",
  "Roger",
  "Roland",
  "Roman",
  "Ronald",
  "Ronan",
  "Ronin",
  "Ronnie",
  "Rory",
  "Roscoe",
  "Roy",
  "Royce",
  "Ruben",
  "Rudy",
  "Russell",
  "Rusty",
  "Ryan",
  "Ryder",
  "Ryker",
  "Rylan",
  "Ryland",
  "Sam",
  "Sammy",
  "Samson",
  "Samuel",
  "Santiago",
  "Saul",
  "Sawyer",
  "Scott",
  "Sean",
  "Sebastian",
  "Sergio",
  "Seth",
  "Shane",
  "Shawn",
  "Sheldon",
  "Shelton",
  "Sherman",
  "Silas",
  "Simon",
  "Solomon",
  "Sonny",
  "Spencer",
  "Stanley",
  "Stefan",
  "Stephen",
  "Sterling",
  "Steve",
  "Steven",
  "Stewart",
  "Stone",
  "Stuart",
  "Sullivan",
  "Tanner",
  "Tate",
  "Taylor",
  "Ted",
  "Terrence",
  "Terry",
  "Theo",
  "Theodore",
  "Thomas",
  "Tim",
  "Timothy",
  "Toby",
  "Todd",
  "Tom",
  "Tommy",
  "Tony",
  "Travis",
  "Trent",
  "Trevor",
  "Trey",
  "Tristan",
  "Troy",
  "Tucker",
  "Tyler",
  "Tyson",
  "Ulysses",
  "Uriel",
  "Valentin",
  "Valentine",
  "Van",
  "Vance",
  "Vaughn",
  "Vernon",
  "Victor",
  "Vincent",
  "Vincenzo",
  "Virgil",
  "Vito",
  "Wade",
  "Walker",
  "Wallace",
  "Walter",
  "Warren",
  "Waylon",
  "Wayne",
  "Wesley",
  "Weston",
  "Wilbur",
  "Wiley",
  "Wilfred",
  "Wilfredo",
  "Will",
  "William",
  "Willie",
  "Willis",
  "Wilson",
  "Winston",
  "Woodrow",
  "Wyatt",
  "Xander",
  "Xavier",
  "Yahir",
  "Yusuf",
  "Zachariah",
  "Zachary",
  "Zahir",
  "Zaiden",
  "Zain",
  "Zaire",
  "Zakai",
  "Zander",
  "Zane",
  "Zayden",
  "Zayn",
  "Zayne",
  "Zechariah",
  "Zion",
  "Zyaire",
  "Zyler",
  "Zyon",
];

const sciFiNames = [
  "Azon",
  "Borin",
  "Caelum",
  "Daelon",
  "Eryndor",
  "Faelan",
  "Galion",
  "Halyon",
  "Ilaris",
  "Jorlan",
  "Krylan",
  "Lythor",
  "Maelis",
  "Naryn",
  "Orlin",
  "Pyrian",
  "Quorin",
  "Rynan",
  "Sylas",
  "Tyrael",
  "Voryn",
  "Wyler",
  "Xylar",
  "Ylona",
  "Zephan",
  "Aranis",
  "Brannon",
  "Caelen",
  "Daelan",
  "Elara",
  "Faelan",
  "Gryndor",
  "Halcyon",
  "Ilian",
  "Joryn",
  "Kalen",
  "Lorian",
  "Myrrh",
  "Nyrian",
  "Orlith",
  "Prynn",
  "Quirin",
  "Rylian",
  "Syllin",
  "Tylis",
  "Varyn",
  "Wynna",
  "Xyris",
  "Yllara",
  "Zevran",
  "Aelar",
  "Brisyn",
  "Caedyn",
  "Daeris",
  "Elion",
  "Faerith",
  "Glynn",
  "Haleth",
  "Ilyas",
  "Joryth",
  "Kaelen",
  "Lysander",
  "Mylen",
  "Nyla",
  "Oran",
  "Pryor",
  "Quillian",
  "Rylen",
  "Sylar",
  "Taryn",
  "Vyran",
  "Wylan",
  "Xylen",
  "Ylian",
  "Zephyr",
  "Aelar",
  "Brithyn",
  "Caelith",
  "Daeron",
  "Elira",
  "Faelon",
  "Glynnis",
  "Halin",
  "Ilith",
  "Joril",
  "Kaelis",
  "Lyran",
  "Mylar",
  "Nysian",
  "Orith",
  "Pyrrhus",
  "Quen",
  "Rynis",
  "Syren",
  "Tylan",
  "Valin",
  "Wrynn",
  "Xylis",
  "Yllin",
  "Zevra",
  "Aelion",
  "Bronn",
  "Caelyn",
  "Daelith",
  "Elorin",
  "Faelith",
  "Gralin",
  "Halion",
  "Ilian",
  "Jaryn",
  "Kaelin",
  "Lyris",
  "Myron",
  "Nyris",
  "Orlan",
  "Pyra",
  "Quinlan",
  "Rynor",
  "Sylvan",
  "Tyrion",
  "Valis",
  "Wyrin",
  "Xyra",
  "Yloris",
  "Zevra",
  "Aeron",
  "Brithis",
  "Caelor",
  "Daeron",
  "Elin",
  "Faelis",
  "Grynn",
  "Halor",
  "Ilyon",
  "Jorin",
  "Kaelis",
  "Lyrian",
  "Mylan",
  "Nyran",
  "Oren",
  "Pyrr",
  "Quorin",
  "Rylor",
  "Sylis",
  "Tynan",
  "Vyris",
  "Wyren",
  "Xylon",
  "Ylith",
  "Zevran",
  "Aelion",
  "Brien",
  "Caelin",
  "Daerys",
  "Elira",
  "Faelor",
  "Glynna",
  "Halan",
  "Ilian",
  "Jorren",
  "Kaelin",
  "Lyrith",
  "Myrion",
  "Nysor",
  "Orin",
  "Pyrris",
  "Quinlan",
  "Rynlan",
  "Sylor",
  "Tynor",
  "Valis",
  "Wyris",
  "Xylin",
  "Ylian",
  "Zephyr",
  "Aeris",
  "Brithan",
  "Caelin",
  "Daelon",
  "Elion",
  "Faelith",
  "Grylin",
  "Halion",
  "Ilis",
  "Jorlin",
  "Kaelis",
  "Lyris",
  "Myron",
  "Nyris",
  "Orlin",
  "Varon",
];
