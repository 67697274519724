import { useEffect, useRef } from "react";
import checkEmailVideo from "../../assets/check-email.mp4";
import { FlowState } from "../../enums/enums";
import styles from "./CheckYourEmailComponent.module.css";
import { useAuth } from "../../context/AuthContext";
import { Button } from "../buttons/Button";

interface Props {
  handleFlowStateChange: (state: FlowState) => void;
  loginDetails: { email: string; password: string };
}

const CheckYourEmailComponent = ({
  handleFlowStateChange,
  loginDetails,
}: Props) => {
  const { signup } = useAuth();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <video
          ref={videoRef}
          autoPlay
          className={styles.backgroundVideo}
          muted
          playsInline
          style={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            objectFit: "cover",
          }}
        >
          <source src={checkEmailVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className={styles.gradient} />
        <div className={styles.pageText}>
          <h1>Check your email</h1>
          <p>We sent a verification link to</p>
          <p>{loginDetails.email}</p>
          <Button
            type="button"
            onClick={() => handleFlowStateChange(FlowState.LOGIN)}
            className={styles.button}
          >
            Go to Login
          </Button>
          <p className={styles.question}>Didn't Receive it?</p>
          <button
            className={styles.emailButton}
            type="button"
            onClick={async () => {
              await signup(loginDetails.email, loginDetails.password);
            }}
          >
            Click to resend
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckYourEmailComponent;
