import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

export const UnauthenticatedLayout = () => {
  const { authState } = useAuth();

  const location = useLocation();

  if (authState?.status === "success") {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (authState?.status === "error") {
    return <Outlet />;
  }
};
