const getCharacterRoles = (path: string) => {
  if (path.includes("fantasy")) return fantasyRoles;
  if (path.includes("horror")) return modernRoles;
  if (path.includes("scifi")) return scifiRoles;
  return [""];
};

export default getCharacterRoles;

const fantasyRoles = [
  "A cunning sorcerer who draws power from ancient dragon bloodlines.",
  "A stoic warrior of the northern tundras, skilled in both axe and spear.",
  "A wise cleric who communes with spirits of the forest.",
  "A charming bard who travels the world seeking tales of heroism.",
  "A fierce barbarian of the Ashen Plains, known for their rage in battle.",
  "A mystical druid who can shape-shift into the mightiest of beasts.",
  "A nimble monk trained in the hidden monasteries of the Jade Mountains.",
  "A mysterious warlock bound to an otherworldly patron of shadows.",
  "A resourceful artificer who crafts wondrous inventions and gadgets.",
  "A valiant paladin who upholds the sacred Oath of the Silver Flame.",
  "A skilled ranger who hunts the undead in haunted forests.",
  "A charismatic rogue who leads a notorious thieves' guild.",
  "A formidable fighter with unmatched skill in dual-wielding swords.",
  "A scholarly wizard who delves into forbidden arcane knowledge.",
  "A dedicated druid who protects ancient groves from corruption.",
  "A bold pirate captain who sails the treacherous Crimson Seas.",
  "A shadowy assassin trained by the Silent Order.",
  "A resilient cleric who heals the wounded on the battlefield.",
  "A seasoned hunter who tracks mythical creatures.",
  "A curious alchemist who experiments with potent elixirs.",
  "A strategic tactician who commands armies with precision.",
  "A daring explorer who seeks the lost city of gold.",
  "A stern inquisitor who hunts down heretics and dark mages.",
  "A clever illusionist who bends reality to their will.",
  "A devout priest who spreads the teachings of the Sun God.",
  "A fierce gladiator who fights for freedom in the arenas.",
  "A reclusive necromancer who raises the dead to serve their will.",
  "A heroic knight who defends the kingdom from monstrous threats.",
  "A wandering minstrel who entertains with songs of legend.",
  "A battle-hardened veteran who has seen countless wars.",
  "A secretive spy who gathers intelligence for the king.",
  "A noble duelist known for their flawless technique.",
  "A mystical shaman who speaks with ancestral spirits.",
  "A relentless bounty hunter who tracks down the most elusive prey.",
  "A dedicated healer who tends to the sick and injured.",
  "A legendary archer who never misses their mark.",
  "A wise sage who offers counsel to those in need.",
  "A bold adventurer who explores uncharted territories.",
  "A devout acolyte who serves in a grand cathedral.",
  "A crafty thief who always has an escape plan.",
  "A fearless berserker who revels in the chaos of battle.",
  "A mysterious medium who communicates with the dead.",
  "A vigilant guardian who protects sacred relics.",
  "A cunning illusionist who can weave the most convincing mirages.",
  "A noble knight who quests for the Holy Grail.",
  "A relentless warrior who never backs down from a fight.",
  "A clever trickster who delights in causing mischief.",
  "A vengeful avenger who seeks justice for a great wrong.",
  "A pious monk who has mastered the art of inner peace.",
  "A ruthless enforcer who maintains order in the underworld.",
  "A brilliant strategist who outsmarts their enemies at every turn.",
  "A fearless sailor who navigates the stormiest seas.",
  "A wise oracle who foretells the future in cryptic visions.",
  "A formidable barbarian who fights with the fury of a storm.",
  "A curious scholar who seeks the secrets of the universe.",
  "A fierce protector who stands guard over ancient ruins.",
  "A shadowy rogue who moves unseen through the night.",
  "A valiant crusader who battles against the forces of darkness.",
  "A clever merchant who always gets the best deal.",
  "A daring acrobat who performs death-defying stunts.",
  "A legendary swordsman who has mastered the art of the blade.",
  "A devoted herbalist who knows the healing properties of every plant.",
  "A fearless captain who leads their crew into the unknown.",
  "A cunning alchemist who creates powerful potions and poisons.",
  "A mystical enchanter who imbues objects with magical power.",
  "A relentless tracker who never loses the scent.",
  "A stoic hermit who lives in isolation on a mountain peak.",
  "A wise elder who dispenses sage advice to those who seek it.",
  "A brave warrior who defends their homeland from invaders.",
  "A skilled blacksmith who forges weapons of legend.",
  "A crafty smuggler who can sneak anything past the authorities.",
  "A devout worshiper who performs sacred rituals to honor their deity.",
  "A ruthless warlord who commands a legion of soldiers.",
  "A fearless gladiator who fights for glory in the coliseum.",
  "A cunning diplomat who negotiates peace between warring factions.",
  "A stealthy scout who gathers vital information behind enemy lines.",
  "A powerful geomancer who controls the very earth itself.",
  "A wise loremaster who knows the history of every kingdom.",
  "A fierce beastmaster who commands a pack of loyal animals.",
  "A master thief who can pick any lock and bypass any trap.",
  "A daring sky pirate who sails the skies in a flying ship.",
  "A relentless inquisitor who roots out heresy wherever it hides.",
  "A brilliant inventor who creates fantastical machines.",
  "A stoic sentinel who guards the gates of the underworld.",
  "A fearless warrior who battles giants in the frozen wastes.",
  "A clever tactician who always has a plan.",
  "A mystical seer who can see into the past and future.",
  "A noble paladin who upholds the highest ideals of chivalry.",
  "A daring swashbuckler who duels with flair and panache.",
  "A wise philosopher who seeks the truth of existence.",
  "A relentless mercenary who fights for the highest bidder.",
  "A fearless demon hunter who battles the forces of hell.",
  "A cunning illusionist who can create the most convincing deceptions.",
  "A bold explorer who delves into ancient tombs in search of treasure.",
  "A dedicated healer who can cure any ailment.",
  "A stealthy ranger who moves silently through the wilderness.",
  "A formidable mage who commands the power of the elements.",
  "A wise druid who protects the balance of nature.",
  "A cunning thief who always has an eye on the prize.",
  "A valiant hero who stands against the darkness and fights for the light.",
  "A cunning diplomat who can sway even the most stubborn of rulers.",
  "A fearless adventurer who explores the deepest dungeons.",
  "A wise elder who guards the secrets of an ancient order.",
  "A relentless warrior who fights with the strength of ten.",
  "A skilled hunter who tracks dangerous beasts in the wilds.",
  "A mystical necromancer who commands legions of the undead.",
  "A daring thief who can steal anything, even a dragon’s treasure",
];

const modernRoles = [
  "Police Officer",
  "Paramedic",
  "Doctor",
  "Lab Tech",
  "Pharmacist",
  "Detective",
  "Local News Reporter",
  "School Teacher",
  "Track Athelete",
  "Local TV Host",
  "Theatre Actor",
  "Movie Star",
  "Musician",
  "Chef",
  "Woodsman",
  "Farmer",
];

const scifiRoles = [
  "The captain of a small starship",
  "A settler on a planet in the outer rim",
  "A lone drifter on a lawless world",
  "A bounty hunter",
  "A wanted criminal who escaped the authorities",
];
