const CreditStarIcon = () => {
  return (
    <div className="h-10 w-10 flex justify-center items-center">
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.26954 4.52851L0.623641 5.18943L0.541355 5.20586C0.416789 5.23831 0.303231 5.30261 0.212277 5.39221C0.121323 5.4818 0.0562326 5.59348 0.0236521 5.71583C-0.00892837 5.83818 -0.00783137 5.96682 0.026831 6.08862C0.0614934 6.21041 0.128479 6.321 0.220948 6.40909L3.58667 9.62365L2.79294 14.1643L2.78347 14.2429C2.77585 14.3693 2.8026 14.4955 2.86099 14.6084C2.91938 14.7213 3.00731 14.817 3.11577 14.8856C3.22424 14.9543 3.34935 14.9934 3.47828 14.999C3.60721 15.0045 3.73534 14.9764 3.84955 14.9174L8.00464 12.7739L12.1503 14.9174L12.2231 14.9503C12.3433 14.9967 12.4739 15.011 12.6016 14.9916C12.7292 14.9721 12.8493 14.9197 12.9495 14.8397C13.0497 14.7597 13.1265 14.655 13.1718 14.5363C13.2172 14.4176 13.2295 14.2893 13.2076 14.1643L12.4131 9.62365L15.7803 6.40838L15.8371 6.34764C15.9183 6.24959 15.9715 6.13219 15.9913 6.00739C16.0111 5.8826 15.9969 5.75487 15.9501 5.63723C15.9032 5.51959 15.8254 5.41624 15.7246 5.3377C15.6238 5.25916 15.5036 5.20825 15.3762 5.19015L10.7303 4.52851L8.65347 0.398672C8.59337 0.279017 8.50034 0.178258 8.3849 0.1078C8.26946 0.0373425 8.13622 0 8.00027 0C7.86432 0 7.73109 0.0373425 7.61565 0.1078C7.50021 0.178258 7.40718 0.279017 7.34708 0.398672L5.26954 4.52851Z"
          fill="#20E5D9"
        />
      </svg>
    </div>
  );
};

export default CreditStarIcon;
