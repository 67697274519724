import { IconSearch } from "@tabler/icons-react";

interface SearchInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  className?: string;
  style?: React.CSSProperties;
}

const SearchInput = ({
  onChange,
  value,
  className,
  style,
}: SearchInputProps) => {
  return (
    <div className={`relative ${className}`} style={{ ...style }}>
      <IconSearch
        size={20}
        style={{ position: "absolute", left: 13, top: 12, opacity: 0.6 }}
      />
      <input
        placeholder="Search any"
        className="color-white"
        style={{
          height: 44,
          width: "100%",
          borderRadius: 5,
          background: "transparent",
          border: "1px solid rgb(100, 100, 100, 0.6)",
          boxSizing: "border-box",
          position: "relative",
          paddingLeft: 44,
          paddingRight: 64,
          marginBottom: 0,
        }}
        type="text"
        onChange={onChange}
        value={value}
      />
    </div>
  );
};
export default SearchInput;
