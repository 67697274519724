const RpgPlaybookIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M38.0505 26.5291C33.2431 32.2391 27.5628 17.8439 20.0801 29.8881"
        stroke="white"
        strokeWidth="1.98334"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.94531 26.5291C6.75274 32.2391 12.433 17.8439 19.9157 29.8881"
        stroke="white"
        strokeWidth="1.48095"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.6471 1.94336H7.348C4.3631 1.94336 1.94336 4.3631 1.94336 7.348V32.6471C1.94336 35.632 4.3631 38.0517 7.348 38.0517H32.6471C35.632 38.0517 38.0517 35.632 38.0517 32.6471V7.348C38.0517 4.3631 35.632 1.94336 32.6471 1.94336Z"
        stroke="white"
        strokeWidth="1.98334"
        stroke-miterlimit="10"
      />
      <path
        d="M20.1983 8.99805L21.6332 12.8759L25.511 14.3108L21.6332 15.7457L20.1983 19.6263L18.7606 15.7457L14.8828 14.3108L18.7606 12.8759L20.1983 8.99805Z"
        fill="white"
      />
      <path
        d="M30.2281 7.79883L30.8334 9.43643L32.471 10.0418L30.8334 10.6498L30.2281 12.2874L29.6227 10.6498L27.9824 10.0418L29.6227 9.43643L30.2281 7.79883Z"
        fill="white"
      />
    </svg>
  );
};

export default RpgPlaybookIcon;
