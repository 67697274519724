const RpgPlaybookLogo = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.132 16.2209C20.26 19.6327 16.8667 11.0313 12.3965 18.2279"
        stroke="white"
        strokeWidth="1.51356"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.53906 16.2209C4.41101 19.6327 7.8044 11.0313 12.2746 18.2279"
        stroke="white"
        strokeWidth="1.13016"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8287 1.59058H4.71505C2.93188 1.59058 1.48633 3.03641 1.48633 4.81993V19.9366C1.48633 21.7201 2.93188 23.1659 4.71505 23.1659H19.8287C21.6119 23.1659 23.0574 21.7201 23.0574 19.9366V4.81993C23.0574 3.03641 21.6119 1.59058 19.8287 1.59058Z"
        stroke="white"
        strokeWidth="1.51356"
        strokeMiterlimit="10"
      />
      <path
        d="M12.402 5.75854L13.2592 8.07561L15.5759 8.933L13.2592 9.7904L12.402 12.1091L11.5432 9.7904L9.22656 8.933L11.5432 8.07561L12.402 5.75854Z"
        fill="white"
      />
      <path
        d="M18.3601 5.04663L18.7217 6.02513L19.7 6.38681L18.7217 6.75012L18.3601 7.72861L17.9985 6.75012L17.0186 6.38681L17.9985 6.02513L18.3601 5.04663Z"
        fill="white"
      />
    </svg>
  );
};

export default RpgPlaybookLogo;
