import IconButton from "./IconButton";
import { v4 as uuidv4 } from "uuid";

interface TextInputProps {
  label?: string;
  value?: string | undefined;
  name?: string;
  buttonIcon?: React.ReactElement;
  onClick?: () => void;
  onChange?: (value: string, name?: string) => void;
  classes?: string;
  type?: string;
  placeholder?: string;
  style?: React.CSSProperties;
}

const TextInput = ({
  label,
  name,
  value,
  buttonIcon,
  onClick = () => console.log("no click set"),
  onChange,
  type = "text",
  placeholder = "",
  style,
}: TextInputProps) => {
  const uniqueID = uuidv4();
  return (
    <div className="bg-trans" style={{ marginBottom: 0, ...style }}>
      <div
        className="input-container flex-row justify-between items-center"
        style={{ marginBottom: 10 }}
      >
        <label
          className="color-white flex-row items-center"
          style={{ fontSize: 14, fontWeight: 600 }}
          htmlFor={uniqueID}
        >
          {label}
        </label>
        {buttonIcon ? (
          <IconButton type="button" icon={buttonIcon} onClick={onClick} />
        ) : (
          <div
            style={{
              width: "24px",
              height: "24px",
            }}
          />
        )}
      </div>
      <input
        name={name}
        id={uniqueID}
        type={type}
        value={value}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value, e.target.name);
          }
        }}
        className="border-fade rounded-sm p-1 bg-trans border-sm-gray w-full color-white"
        placeholder={placeholder}
        style={{ marginBottom: 0 }}
      />
    </div>
  );
};

export default TextInput;
