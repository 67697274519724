import { useFetch } from "../../context/FetchContext";
import {
  UseMutateFunction,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import { Me, Product } from "../../types";
import { AxiosResponse } from "axios";
import {
  IconArrowLeft,
  IconExternalLink,
  IconStarFilled,
} from "@tabler/icons-react";
import styles from "./SubscriptionsPage.module.css";
import { useAuth } from "../../context/AuthContext";
import { Button } from "../../components/buttons/Button";
import RpgPlaybookLogo from "../../icons/RpgPlaybookLogo";
interface SubBoxProps {
  me?: Me;
  product: Product;
  index: number;
  createPortalSession: UseMutateFunction<
    AxiosResponse<any, any>,
    Error,
    void,
    unknown
  >;
  createPortalSessionIsPending: boolean;
}

const SubBox = ({
  index,
  me,
  product,
  createPortalSession,
  createPortalSessionIsPending,
}: SubBoxProps) => {
  const { authFetch } = useFetch();

  const { mutate: createCheckoutSession, isPending } = useMutation({
    mutationFn: async (stripePlanId: string) => {
      const response = await authFetch.post(
        "/v1/billing/create-checkout-session",
        {
          planId: stripePlanId,
        }
      );

      return response.data;
    },
    onSuccess: (data) => {
      window.location.href = data.url;
    },
  });

  const active = product.id === me?.subscription?.price?.product?.id;

  const price = product.prices.find((price) => price.active === true);

  return (
    <div
      className={styles.subscriptionBox}
      style={{
        backgroundImage: `url(${product.image})`,
      }}
    >
      <div className={styles.boxHeader}>
        <h1
          className={styles.productName}
          style={{ color: index === 0 ? "#F8E687" : "#AADBF9" }}
        >
          {product.name}
        </h1>
      </div>
      <div className={styles.content}>
        <div className={styles.featuresList}>
          {product?.metadata?.features?.split(",").map((feature: string) => (
            <div key={feature} className={styles.featureItem}>
              <IconStarFilled
                size={18}
                color={index === 0 ? "#F8E687" : "#AADBF9"}
              />
              <div className={styles.featureText}>{feature}</div>
            </div>
          ))}
        </div>
        <div className={styles.priceContainer}>
          <div>
            <h1>£{price?.unitAmount ? price.unitAmount / 100 : 0}</h1>
            <p className={styles.perMonth}>Per Month</p>
          </div>
          <Button
            variant={active ? "secondary" : "primary"}
            onClick={() => {
              if (me?.subscription) {
                createPortalSession();
              } else if (price) {
                createCheckoutSession(price?.id);
              }
            }}
            className={styles.subscriptionButton}
            disabled={isPending || createPortalSessionIsPending}
          >
            {active ? "Subscribed" : "Subscribe"}
          </Button>
        </div>
      </div>
    </div>
  );
};

const SubscriptionsPage = () => {
  const { authFetch, apiClient } = useFetch();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const { data: me, isLoading: meIsLoading } = useQuery({
    queryKey: ["me"],
    queryFn: async () => {
      const response = await apiClient.api.v1.users.me.$get();
      const data = await response.json();
      return data;
    },
  });

  const { data: products, isLoading: productsIsLoading } = useQuery({
    queryKey: ["products", "subscription"],
    queryFn: async () => {
      const response = await apiClient.api.v1.products.$get();
      const data = await response.json();

      const subscriptionProducts = data.filter(
        (product) => (product.metadata as any)?.type === "subscription"
      );

      return subscriptionProducts;
    },
  });

  const {
    mutate: createPortalSession,
    isPending: createPortalSessionIsPending,
  } = useMutation({
    mutationFn: async () => {
      return await authFetch.post("v1/billing/create-portal-session", {
        returnUrl: `${window.location.origin}`,
      });
    },
    onSuccess: ({ data }) => {
      window.location.href = data.url;
    },
  });

  if (meIsLoading || productsIsLoading) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.headerLeft}>
          {me?.onboarded ? (
            <IconArrowLeft onClick={() => navigate("/account")} />
          ) : (
            <RpgPlaybookLogo />
          )}
        </div>
        <div>
          <h4 className={styles.title}>
            {me?.onboarded ? "Subscribe" : "Welcome to RPG Playbook"}
          </h4>
          {!me?.onboarded && <p className={styles.email}>{me?.email}</p>}
        </div>
      </header>
      <main className={styles.productsContainer}>
        {!me?.onboarded && (
          <p className={styles.chooseSubscription}>Choose your subscription</p>
        )}
        {products
          ?.sort(
            (a, b) =>
              Number((b.metadata as any)?.credits) -
              Number((a.metadata as any)?.credits)
          )
          .map((product, index) => {
            return (
              <SubBox
                index={index}
                key={product.id}
                createPortalSession={createPortalSession}
                createPortalSessionIsPending={createPortalSessionIsPending}
                me={me as Me}
                product={product as Product}
              />
            );
          })}

        {me?.subscription && (
          <button
            className={styles.manageSubscriptionButton}
            style={{ width: "100%" }}
            disabled={createPortalSessionIsPending}
            onClick={() => createPortalSession()}
          >
            Manage Subscription
            <IconExternalLink opacity={0.6} />
          </button>
        )}
        {!me?.onboarded && (
          <Button
            variant="secondary"
            onClick={logout}
            style={{ width: "100%" }}
          >
            Sign out
          </Button>
        )}
      </main>
    </div>
  );
};

export default SubscriptionsPage;
