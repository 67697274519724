import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import getCreature from "../../assetData/creatureData";
import AppShell from "../../components/AppShell";
import Loading from "../../components/Loading";
import RadioButtons from "../../components/RadioButtons";
import TextInput from "../../components/TextInput";
import { useFetch } from "../../context/FetchContext";
import { Sizes } from "../../enums";
import ArrowShuffleIcon from "../../icons/ArrowsShuffleIcon";
import CreateHeader from "../../components/headers/CreateHeader";
import CreditsNeeded from "../../components/CreditsNeeded";
import { ErrorPage } from "../ErrorPage/ErrorPage";
import { AxiosError } from "axios";
import { InsufficientCreditsPage } from "../InsufficientCreditsPage/InsufficientCreditsPage";
import { Button } from "../../components/buttons/Button";

const getRandomSize = () => {
  const sizes = [Sizes.Small, Sizes.Medium, Sizes.Large];
  return sizes[Math.floor(Math.random() * sizes.length)];
};

interface CreateCreatureProps {}

const CreateCreature = ({}: CreateCreatureProps) => {
  const { themeId } = useParams();
  const [type, setType] = useState("");
  const [size, setSize] = useState(getRandomSize());

  const [credits, setCredits] = useState(0);

  const location = useLocation();

  useEffect(() => {
    authFetch("/v1/users/me").then((results) => {
      setCredits(results.data.credits);
    });
  }, []);

  const getRandomCreature = () => {
    const creatures = getCreature(location.pathname);

    return creatures[Math.floor(Math.random() * creatures.length)];
  };

  const { authFetch } = useFetch();
  const navigate = useNavigate();

  const handleTypeChange = (value: string) => {
    setType(value);
  };

  const handleSizeChange = (value: Sizes) => {
    setSize(value);
  };

  const {
    mutate: handleCreateCreature,
    isPending,
    isError,
    reset,
    error,
  } = useMutation({
    mutationFn: async () => {
      return await authFetch.post(`v1/creatures`, {
        theme: themeId,
        type: type,
        size: size,
      });
    },
    onSuccess: (result) => {
      navigate(`/${themeId}/asset/creature/${result.data.id}`);
    },
  });

  if (isError) {
    if ((error as AxiosError)?.response?.data === "Insufficient credits") {
      return <InsufficientCreditsPage />;
    }
    return <ErrorPage handleOnClick={() => reset()} />;
  }

  if (!isPending) {
    return (
      <AppShell
        header={<CreateHeader title="New Creature" subtitle={themeId} />}
      >
        <div className="flex-col justify-between">
          <div
            id="character-maker-form"
            className="grow flex flex-col justify-between"
          >
            <TextInput
              style={{ marginBottom: 32 }}
              label="Type"
              value={type}
              onChange={handleTypeChange}
              onClick={() => setType(getRandomCreature())}
              buttonIcon={<ArrowShuffleIcon />}
            />
            <RadioButtons
              classes="mt-6"
              options={[Sizes.Small, Sizes.Medium, Sizes.Large]}
              title="Size"
              onClick={handleSizeChange}
              selectedValue={size}
            />
            <div className="grow flex flex-col justify-end">
              <CreditsNeeded credits={credits} creditsNeeded={20} />
              <Button
                variant="secondary"
                onClick={() => handleCreateCreature()}
              >
                Create Creature
              </Button>
            </div>
          </div>
        </div>
      </AppShell>
    );
  } else {
    return (
      <Loading>
        <p>Creating your asset...</p>
      </Loading>
    );
  }
};

export default CreateCreature;
