import AppShell from "../../components/AppShell";
import SearchInput from "../../components/SearchInput";
import BrowseCard from "../../components/BrowseCard";
import Tabs from "../../components/Tabs";
import { useDebounce, useLocalStorage } from "@uidotdev/usehooks";
import { useState } from "react";
import { useFetch } from "../../context/FetchContext";
import { useQuery } from "@tanstack/react-query";
import SelectableBadgesCarousel, {
  AssetType,
} from "../../components/SelectableBadges/SelectableBadgesCarousel";

enum Tab {
  My_Assets = "My Assets",
  All_Assets = "All Assets",
}

const BrowseAssets = () => {
  const [selectedAssetType, setSelectedAssetType] = useLocalStorage<AssetType>(
    "selected-asset-types",
    "Any"
  );
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 500);
  const { apiClient } = useFetch();

  const [activeTab, setActiveTab] = useLocalStorage(
    "selected-tab",
    Tab.My_Assets
  );

  const { data: assets, error } = useQuery({
    queryKey: ["assets", debouncedSearch, activeTab, selectedAssetType],
    queryFn: async () => {
      const ownership = activeTab === Tab.All_Assets ? "all" : "my";
      const assetType = selectedAssetType.toLowerCase();

      let assetsResponse;

      const options = {
        param: {
          ownership,
        },
        query: {
          search,
        },
      };

      const assetApiMap: Record<string, string> = {
        any: "any",
        creatures: "creatures",
        items: "items",
        locations: "locations",
        characters: "characters",
      };

      const assetEndpoint = assetApiMap[assetType] as keyof typeof assetApiMap;
      if (assetEndpoint) {
        assetsResponse =
          await apiClient.api.v1.assets[":ownership"][
            assetEndpoint as keyof (typeof apiClient.api.v1.assets)[":ownership"]
          ].$get(options);
      }

      if (!assetsResponse) {
        return [];
      }

      const { assets } = await assetsResponse.json();

      return assets;
    },
  });

  const handleTabChange = (selectedTab: Tab) => {
    setActiveTab(selectedTab);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <AppShell title="Assets" backBtn={true} browseButton={false}>
      <div>
        <Tabs
          fullWidth
          tabNames={[Tab.My_Assets, Tab.All_Assets]}
          activeTab={activeTab}
          onClick={handleTabChange}
          style={{ marginBottom: 0 }}
        />
        <SelectableBadgesCarousel
          selectedAssetType={selectedAssetType}
          setSelectedAssetType={setSelectedAssetType}
        />

        <SearchInput
          className="mb-1"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearch(e.target.value)
          }
          value={search}
        />

        <div className="grow grid grid-cols-2 gap-1">
          {assets?.map((asset: any) => {
            return (
              <BrowseCard
                key={`${asset?.assetType}${asset?.id}`}
                img={asset?.image?.url || ""}
                alt="placeholder"
                title={asset?.name || asset?.type}
                description={asset?.size || asset?.type || asset?.role}
                link={`/fantasy/asset/${asset?.assetType}/${asset.id}`}
              />
            );
          })}
        </div>
      </div>
    </AppShell>
  );
};

export default BrowseAssets;
