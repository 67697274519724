import CircleTickIcon from "../icons/CircleTickIcon";

interface CheckItemProps {
  checked: boolean;
  name: string;
}

const CheckItem = ({ name, checked }: CheckItemProps) => {
  return (
    <li className="flex-row items-center">
      <CircleTickIcon color={checked ? "#2be6da" : "gray"} />
      <div style={{ marginLeft: 10, opacity: 0.6 }}>{name}</div>
    </li>
  );
};

export default CheckItem;
