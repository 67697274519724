interface CharacterIconProps {
  style?: React.CSSProperties;
  iconWidth?: string;
  iconHeight?: string;
}

const CharacterIcon = ({
  style,
  iconWidth = "26px",
  iconHeight = "26px",
}: CharacterIconProps) => {
  return (
    <div className="flex justify-center items-center" style={{ ...style }}>
      <svg
        width={iconWidth}
        height={iconHeight}
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.8">
          <path
            d="M26.7798 22.4285V22.4285C26.7798 23.9632 27.8002 25.3111 29.2652 25.7683C30.1879 26.0562 31.3017 26.4157 32.4902 26.8251C34.0109 27.3489 34.6938 27.9529 34.9947 28.4336C35.3177 28.9498 35.206 29.613 35.206 30.2219V30.2219C35.206 32.462 34.0464 34.5432 31.8956 35.1697C29.906 35.7492 26.9974 36.2832 23.1111 36.2316C19.2248 36.2832 16.3163 35.7492 14.3266 35.1697C12.1758 34.5432 11.0162 32.462 11.0162 30.2219V30.2219C11.0162 29.613 10.9045 28.9498 11.2276 28.4336C11.5284 27.9529 12.2114 27.3489 13.7321 26.8251C14.9206 26.4157 16.0343 26.0562 16.957 25.7683C18.4221 25.3111 19.4425 23.9632 19.4425 22.4285V22.4285"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24.2001 9H22.0093C19.5715 9 17.5952 10.9684 17.5952 13.3966V18.7939C17.5952 21.222 19.5715 23.1905 22.0093 23.1905H24.2001C26.6379 23.1905 28.6141 21.222 28.6141 18.7939V13.3966C28.6141 10.9684 26.6379 9 24.2001 9Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
};

export default CharacterIcon;
