import LeftArrowIcon from "../../../icons/LeftArrowIcon";
import IconButton from "../../IconButton";
import styles from "./HeaderV3.module.css";
export const HeaderV3 = (props: { title: string }) => {
  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <IconButton icon={<LeftArrowIcon />} to="/" type="link" />
        <h3 className={styles.title}>{props.title}</h3>
      </div>
    </header>
  );
};
