import React, { useState } from "react";
import styles from "./ImageLoaderWithTransition.module.css";
import imageLoader from "../../assets/image-loader.gif";

interface ImageLoaderWithTransitionProps {
  imageUrl: string | undefined;
  imageName: string | undefined;
  children: React.ReactNode;
}

const ImageLoaderWithTransition: React.FC<ImageLoaderWithTransitionProps> = ({
  imageUrl,
  imageName,
  children,
}) => {
  const [isLoading] = useState(() => (imageUrl ? false : true));
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className={styles.container}>
      {/* Loading screen */}
      <div
        className={`${styles.loadingScreen} ${isLoading ? styles.visible : styles.hidden}`}
      >
        <img
          src={imageLoader}
          className={styles.loaderImage}
          alt="Asset Artwork"
        />
        <p className={styles.loadingText}>Generating asset art</p>
        <p className={styles.loadingSubtext}>This can take up to 1 min</p>
      </div>

      {/* Image container */}
      {imageUrl && (
        <div
          className={`${styles.imageContainer} ${imageLoaded ? styles.visible : styles.hidden}`}
        >
          <img
            onLoad={() => {
              setTimeout(() => {
                setImageLoaded(true);
              }, 100);
            }}
            src={imageUrl}
            alt={imageName || "Generated character art"}
            className={`${styles.image} ${imageLoaded && styles.zoom}`}
          />
          <div className={styles.gradientOverlay} />
        </div>
      )}
      <div className={styles.floatingInfo}>{children}</div>
    </div>
  );
};

export default ImageLoaderWithTransition;
