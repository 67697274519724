import { FormEvent, useState, useEffect } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import { publicFetch } from "../../utils/fetch";
import TextInput from "../../components/TextInput";
import AuthPageHeader from "../../components/authflow/AuthPageHeader";
import CheckItem from "../../components/CheckItem";
import { Button } from "../../components/buttons/Button";

interface ResetPasswordData {
  token: string;
  password: string;
  confirmPassword: string;
}

interface ResetPasswordResponse {
  message: string;
}

interface PasswordValidation {
  hasMinLength: boolean;
  hasSpecialChar: boolean;
  hasNumber: boolean;
  passwordsMatch: boolean;
}

const resetPassword = async ({
  token,
  password,
  confirmPassword,
}: ResetPasswordData) => {
  const response = await publicFetch.post<ResetPasswordResponse>(
    `/v1/auth/reset-password`,
    { token, password, confirmPassword }
  );
  return response.data;
};

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") as string;

  const [values, setValues] = useState({ password: "", confirmPassword: "" });
  const [validation, setValidation] = useState<PasswordValidation>({
    hasMinLength: false,
    hasSpecialChar: false,
    hasNumber: false,
    passwordsMatch: false,
  });

  const mutation = useMutation<
    ResetPasswordResponse,
    AxiosError,
    ResetPasswordData
  >({ mutationFn: resetPassword, onSuccess: () => navigate("/login") });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (Object.values(validation).every(Boolean)) {
      mutation.mutate({ token, ...values });
    }
  };

  const handleOnChange = (value: string, name?: string) => {
    setValues((prev) => ({ ...prev, [name as string]: value }));
  };

  useEffect(() => {
    const { password, confirmPassword } = values;
    setValidation({
      hasMinLength: password.length >= 8,
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      hasNumber: /\d/.test(password),
      passwordsMatch: password === confirmPassword && password !== "",
    });
  }, [values]);

  const isFormValid = Object.values(validation).every(Boolean);

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ minHeight: "100svh", display: "grid", padding: 16 }}>
        <AuthPageHeader
          message="Already a member?"
          buttonLabel="Sign In"
          onClick={() => navigate("/login")}
        />
        <div>
          <h1 style={{ textAlign: "left" }}>Reset Password</h1>
          <div className="color-fade">Create a new, strong password</div>
          <div>
            <TextInput
              name="password"
              placeholder="New Password"
              value={values.password}
              onChange={handleOnChange}
              type="password"
            />
          </div>
          <div className="flex-col items-start color-fade">
            <div className="color-fade" style={{ marginTop: 16 }}>
              Password must contain:
            </div>
            <ul className="color-fade mt-1">
              <CheckItem
                name="8 or more characters"
                checked={validation.hasMinLength}
              />
              <CheckItem
                name="1 or more special characters"
                checked={validation.hasSpecialChar}
              />
              <CheckItem
                name="1 or more numbers"
                checked={validation.hasNumber}
              />
              <CheckItem
                name="Passwords match"
                checked={validation.passwordsMatch}
              />
            </ul>
          </div>
          <div className="flex-col" id="reset-password-confirm-container">
            <TextInput
              name="confirmPassword"
              placeholder="Confirm New Password"
              value={values.confirmPassword}
              onChange={handleOnChange}
              type="password"
            />
            <Button
              variant="secondary"
              style={{ marginTop: 24 }}
              disabled={!isFormValid}
            >
              Reset Password
            </Button>
          </div>
        </div>
        <NavLink to="/privacy" className="flex-row justify-center mb-2">
          Privacy policy
        </NavLink>
      </div>
    </form>
  );
};
