interface PipIconProps {
  className: string;
}

const PipIcon = ({ className }: PipIconProps) => {
  return (
    <div className={`flex-row items-center justify-center ${className}`}>
      <svg
        width="3"
        height="3"
        viewBox="0 0 3 3"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle opacity="0.6" cx="1.5" cy="1.5" r="1.5" fill="#D9D9D9" />
      </svg>
    </div>
  );
};

export default PipIcon;
