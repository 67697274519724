const ArrowShuffleIcon = () => {
  return (
    <div className="flex-col items-center justify-center">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 4L21 7M21 7L18 10M21 7L16 7.00001C14.9179 6.99841 13.8647 7.34946 13 8.00001M18 20L21 17M21 17L18 14M21 17H16C14.6739 17 13.4021 16.4732 12.4645 15.5355C11.5268 14.5979 11 13.3261 11 12C11 10.6739 10.4732 9.40215 9.53553 8.46447C8.59785 7.52679 7.32608 7 6 7H3M9 16C8.13506 16.6502 7.08204 17.0012 6 17H3"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default ArrowShuffleIcon;
