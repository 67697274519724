interface LeftArrowIconProps {
  style?: React.CSSProperties;
}

const LeftArrowIcon = ({ style }: LeftArrowIconProps) => {
  return (
    <div
      className="flex-col items-center justify-cetner"
      style={{
        width: "28px",
        height: "28px",
        ...style,
      }}
    >
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.8335 14H22.1668M5.8335 14L12.8335 21M5.8335 14L12.8335 7"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default LeftArrowIcon;
